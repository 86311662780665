import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: "c-dropzone",
    onDragenter: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    onDragleave: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDragLeave && _ctx.onDragLeave(...args)), ["stop"])),
    onDragover: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onDragOver($event)), ["stop","prevent"])),
    onDrop: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.onDrop(_ctx.value, $event)), ["stop"]))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 32))
}