const getCssVariable = (selector: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(selector);
};

const rgbStringToHex = (rgb: string): string => {
  const rgbComponents: string[] = rgb.split(',');
  return rgbToHex(
    Number(rgbComponents[0]),
    Number(rgbComponents[1]),
    Number(rgbComponents[2])
  );
};

const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r: number, g: number, b: number): string => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export { getCssVariable, rgbStringToHex, componentToHex, rgbToHex };
