import { computed, SetupContext, ComputedRef } from 'vue';
import { getCssVariable }                      from '@/helpers/css-helpers';

enum BorderType {
  BOTTOM = 'bottom',
  TOP = 'top',
  TRANSPARENT = 'transparent'
}

enum BackgroundType {
  NEUTRAL = '#ffffff',
  GRAY = '#a1a1a1'
}

interface ExtendedDragEvent extends DragEvent {
  path: ExtendedEventTarget[];
  target: HTMLElement;
}

interface ExtendedEventTarget extends EventTarget {
  classList: DOMTokenList;
  style: CSSStyleDeclaration;
}

interface UseDragAndDrop {
  onDragStart: (event: ExtendedDragEvent, item: unknown) => void;
  onDragOver: (event: ExtendedDragEvent) => void;
  onDragLeave: (event: ExtendedDragEvent) => void;
  onDrop: (item: unknown, event: ExtendedDragEvent) => void;
}

let draggedElement: HTMLElement | null = null;
let draggedElementItem: unknown;
let isElementDisabled = false;

export const useDragAndDrop = (context: SetupContext): UseDragAndDrop => {
  const borderColor: ComputedRef<string> = computed(() => getCssVariable('--gray-400'));

  const addBorder = (el: ExtendedEventTarget, type: BorderType): void => {
    if (type === BorderType.BOTTOM) {
      el.style.borderBottomColor = borderColor.value;
      el.style.borderTopColor = BorderType.TRANSPARENT;
    } else if (type === BorderType.TOP) {
      el.style.borderTopColor = borderColor.value;
      el.style.borderBottomColor = BorderType.TRANSPARENT;
    } else {
      el.style.borderBottomColor = BorderType.TRANSPARENT;
      el.style.borderTopColor = BorderType.TRANSPARENT;
    }
  };

  const addBackground = (el: ExtendedEventTarget, type: BackgroundType): void => {
    if(type === BackgroundType.GRAY) {
      el.style.backgroundColor = borderColor.value;
      el.style.borderRadius = '2rem';
    }
    else{
      el.style.backgroundColor = type;
      el.style.borderRadius = '0';
    }
  }

  const getElement = (event: ExtendedDragEvent): HTMLElement | undefined => {
    const path: HTMLElement[] = event.composedPath() as HTMLElement[];

    return path.find((e: HTMLElement) => e?.classList?.contains('c-dropzone' || false)) || undefined;
  };

  const checkIfElementIsDisabled = (element?: HTMLElement) => !!element?.getAttribute('is-disabled');

  const onDragStart = (event: ExtendedDragEvent, item: unknown): void => {
    const element: HTMLElement | undefined = getElement(event) as HTMLElement;
    isElementDisabled = checkIfElementIsDisabled(element);

    if (event.dataTransfer) {
      if (isElementDisabled) {
        event.dataTransfer.dropEffect = 'none';
        event.dataTransfer.effectAllowed = 'none';
      } else {
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.effectAllowed = 'move';
      }
    }

    draggedElement = element;
    draggedElementItem = item;
  };

  const onDragOver = (event: ExtendedDragEvent): void => {
    if (draggedElement && !isElementDisabled) {
      const element: HTMLElement | undefined = getElement(event);
      if (element) {
        if (checkIfElementIsDisabled(element as HTMLElement)){
          if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'none';
            event.dataTransfer.effectAllowed = 'none';
          }
        } else {
          addBorder(element, BorderType.BOTTOM);
          addBackground(element, BackgroundType.GRAY);
        }
      }
    }
  };

  const onDragLeave = (event: ExtendedDragEvent): void => {
    const element: HTMLElement | undefined = getElement(event);
    if (element) {
      addBorder(element, BorderType.TRANSPARENT);
      addBackground(element, BackgroundType.NEUTRAL);
    }
  };

  const onDrop = (item: unknown, event: ExtendedDragEvent): void => {
    if (draggedElement && !isElementDisabled) {
      const element: HTMLElement | undefined = getElement(event);
      if (element) {
        addBorder(element, BorderType.TRANSPARENT);
        addBackground(element, BackgroundType.NEUTRAL);
      }

      context.emit('onDropEvent', { draggedElementItem, item });
    }

    isElementDisabled = false;
  };

  return {
    onDragStart,
    onDragOver,
    onDragLeave,
    onDrop,
  };
};
