import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")

  return (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.fileUrls, (image, index) => {
    return (_openBlock(), _createBlock("div", { key: image }, [
      _createVNode(_component_icon_close, {
        class: "p-f5 pb-f8 w-8 h-8 float-right cursor-pointer",
        onClick: ($event: any) => (_ctx.$emit('removeImage', index))
      }, null, 8, ["onClick"]),
      (_openBlock(), _createBlock("img", {
        key: index,
        alt: image,
        class: "object-center opacity-30 w-1/2 pb-f8 px-f21",
        src: image
      }, null, 8, ["alt", "src"]))
    ]))
  }), 128))
}