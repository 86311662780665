
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useDeviceSize } from '@/hooks/use-page-size';
import {
  useTranslations,
  useGlobalTranslations,
} from '@/hooks/use-translations';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { Issue, IssueMainType } from '@/views/issues/issues.types';
import {
  priority,
  related,
  status,
  type,
} from '@/views/issues/helpers/variables';
import IconMaintenance from '@/components/icons/icon-maintenance.component.vue';
import IconInsurance from '@/components/icons/icon-insurance.component.vue';

export default defineComponent({
  name: 'CIssueDetailsDescription',
  components: { IconMaintenance, IconInsurance },
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { getSelectedIssue } = useIssues(UseIssuesContext.ISSUES);
    const issueData: ComputedRef<Issue> = computed(
      () => store.getters['issues/getIssueInformation']
    );
    const iconRendering = (name: string): string => {
      return name === IssueMainType.MAINTENANCE
        ? 'icon-maintenance'
        : 'icon-insurance';
    };

    return {
      priority,
      status,
      type,
      related,
      issueData,
      getSelectedIssue,
      isMobile,
      iconRendering,
      useTranslations,
      useGlobalTranslations,
    };
  },
});
