
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { FiltersConfiguration, ViewModes } from '@/views/issues/issues.types';
import CIssuesFiltersController
  from '@/views/issues/components/issues-filters-panel/components/issues-filters-controller.component.vue';
import CIssuesFiltersForm
  from '@/views/issues/components/issues-filters-panel/components/issues-filters-form.component.vue';
import { IssuesParams } from '@/store/store.types';


export default defineComponent({
  name: 'CIssuesFiltersPanel',
  components: { CIssuesFiltersForm, CIssuesFiltersController },
  setup() {
    const { store } = useGlobals();
    const filtersConfiguration: ComputedRef<FiltersConfiguration> = computed(() => store.state.issues.options)
    const issueParams: ComputedRef<IssuesParams> = computed(() => store.state.issuesParams)
    const viewMode: ComputedRef<ViewModes> = computed(() => store.state.views.vIssues.viewMode);
    const currentConfigurationUuid: ComputedRef<string> = computed(() => store.state.issues.filtersConfigUuid);


    return {
      currentConfigurationUuid,
      viewMode,
      issueParams,
      filtersConfiguration,
    };
  },
});
