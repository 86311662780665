
import { defineComponent } from 'vue';
import IconClose from '@/components/icons/icon-close.component.vue';

export default defineComponent({
  name: 'CFormMultiSelectTag',
  components: { IconClose },
  props: {
    label: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    maximumTags: {
      type: Number,
      required: false,
      default: 3,
    }
  },
  emits: ['delete-item'],
});
