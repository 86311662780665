
import { defineComponent, onMounted, PropType, reactive, Ref, ref } from 'vue';
import { useTranslations }                                          from '@/hooks/use-translations';
import { IssueDetailsNavigation }                                   from '@/views/issues/components/issues-details/issue-details.types';

export default defineComponent({
  name: 'CIssueDetailsNavigation',
  props: {
    activeNavigation: {
      type: String as PropType<IssueDetailsNavigation>,
      required: true,
    },
  },
  emits: [ 'change-navigation' ],
  setup(props,context) {
    const activeTab: Ref<HTMLElement | null> = ref(null);

    interface HighlightStyles {
      [ key: string ]: string | number;
    }
    const setActiveElement = (event: MouseEvent, tab: IssueDetailsNavigation) => {
      activeTab.value = event.target as HTMLElement;
      recalculateHighlightStyles();
      context.emit('change-navigation', tab);
    };

    const highlightStyles: HighlightStyles = reactive({
      left: 0,
      width: 0,
    });

    const recalculateHighlightStyles = (): void => {
      highlightStyles.left = activeTab.value ? `${activeTab.value.offsetLeft}px` : '0';
      highlightStyles.width = activeTab.value ? `${activeTab.value?.offsetWidth}px` : '0';
    };

    onMounted(() => {
      recalculateHighlightStyles();
    })

    return {
      setActiveElement,
      activeTab,
      highlightStyles,
      IssueDetailsNavigation,
      useTranslations,
    };
  },
});
