import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center my-f13 gap-2" }
const _hoisted_2 = { class: "flex-grow text-red-300" }
const _hoisted_3 = { class: "self-end flex gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_combobox = _resolveComponent("c-combobox")
  const _component_c_button_with_icon = _resolveComponent("c-button-with-icon")
  const _component_c_filters_confirmation_modal = _resolveComponent("c-filters-confirmation-modal")
  const _component_c_modal = _resolveComponent("c-modal")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_c_combobox, {
        selected: _ctx.selectedUuid,
        "onUpdate:selected": _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedUuid = $event)),
        "input-id": "filters-combobox",
        items: _ctx.allFilters,
        label: _ctx.useTranslations('filterSelect')
      }, null, 8, ["selected", "items", "label"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_c_button_with_icon, {
        "button-active-title": _ctx.useTranslations('tooltipsLabel.activeRemoveFilter'),
        "button-disabled-title": _ctx.useTranslations('tooltipsLabel.disabledRemoveFilter'),
        class: "cursor-pointer ",
        "class-list": _ctx.IconButtonClass.RED,
        disabled: !_ctx.selectedUuid,
        icon: _ctx.iconTypes.DISCARD,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeConfirmationModalContext(_ctx.ConfirmationStatement.DELETE_ITEM_CONFIRMATION)))
      }, null, 8, ["button-active-title", "button-disabled-title", "class-list", "disabled", "icon"]),
      _createVNode(_component_c_button_with_icon, {
        "button-active-title": _ctx.useTranslations('tooltipsLabel.activeSaveFilter'),
        "button-disabled-title": _ctx.useTranslations('tooltipsLabel.disabledSaveFilter'),
        "class-list": _ctx.IconButtonClass.GREEN,
        disabled: !_ctx.amountOfSelectedFilters,
        icon: _ctx.iconTypes.SAVE,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeConfirmationModalContext(_ctx.ConfirmationStatement.SAVE_ITEM_CONFIRMATION)))
      }, null, 8, ["button-active-title", "button-disabled-title", "class-list", "disabled", "icon"])
    ]),
    _createVNode(_component_c_modal, {
      "is-open": _ctx.isModalVisible,
      onCloseModal: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_c_filters_confirmation_modal, {
          "confirmation-state": _ctx.confirmationState,
          onCloseModal: _ctx.closeModal,
          onRemoveConfiguration: _ctx.handleDeleteConfiguration,
          onSaveConfiguration: _ctx.handleSaveConfiguration
        }, null, 8, ["confirmation-state", "onCloseModal", "onRemoveConfiguration", "onSaveConfiguration"])
      ]),
      _: 1
    }, 8, ["is-open", "onCloseModal"])
  ]))
}