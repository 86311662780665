import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col-reverse sm:flex-row sm:items-center sm:justify-between mb-f13 p-f3" }
const _hoisted_2 = { class: "flex items-center mt-f8" }
const _hoisted_3 = { class: "flex items-center mb-f8" }
const _hoisted_4 = { class: "ml-f8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circe_icon_wrapper = _resolveComponent("circe-icon-wrapper")
  const _component_c_custom_switch = _resolveComponent("c-custom-switch")
  const _component_icon_document_excel = _resolveComponent("icon-document-excel")
  const _component_c_searchbar = _resolveComponent("c-searchbar")
  const _component_icon_filter = _resolveComponent("icon-filter")
  const _component_c_issues_filters_panel = _resolveComponent("c-issues-filters-panel")
  const _component_c_side_panel_with_background = _resolveComponent("c-side-panel-with-background")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.icons, (icon) => {
          return (_openBlock(), _createBlock(_component_circe_icon_wrapper, {
            key: icon.name,
            "active-icon": _ctx.activeIcon,
            "onUpdate:active-icon": _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeIcon = $event)),
            active: _ctx.activeIcon === icon.name,
            "active-color": icon.color,
            "bigger-icon": true,
            class: "mx-f3 xs:mx-f5",
            component: icon.name,
            title: _ctx.useTranslations(`tooltipsLabel.${icon.label}`),
            onUpdateParams: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateIssuesFilterParam($event)))
          }, null, 8, ["active-icon", "active", "active-color", "component", "title"]))
        }), 128)),
        _createVNode(_component_c_custom_switch, {
          modelValue: _ctx.isNearDeadline,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.isNearDeadline = $event)),
          icon: "icon-warning",
          "left-label": _ctx.useTranslations('lessThan2days'),
          "right-label": _ctx.useTranslations('all'),
          type: _ctx.switchTypes.SINGLE
        }, null, 8, ["modelValue", "left-label", "right-label", "type"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_icon_document_excel, {
          class: "w-10 h-10 ml-f8 mr-8 cursor-pointer",
          onClick: _ctx.checkExcelDocument
        }, null, 8, ["onClick"]),
        ( !_ctx.isMobile&&_ctx.issuesParamsType!==_ctx.IssueMainType.OBSERVED)
          ? (_openBlock(), _createBlock(_component_c_custom_switch, {
              key: 0,
              modelValue: _ctx.viewMode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.viewMode = $event)),
              class: "mr-f21",
              icon: "icon-warning",
              "left-label": _ctx.useTranslations('statusMode'),
              "right-label": _ctx.useTranslations('tableMode'),
              type: _ctx.switchTypes.DOUBLE
            }, null, 8, ["modelValue", "left-label", "right-label", "type"]))
          : _createCommentVNode("", true),
        _createVNode(_component_c_searchbar, {
          class: "w-96",
          search: _ctx.searchQuery,
          "onUpdate:search": _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateIssuesSearchQueryParam($event)))
        }, null, 8, ["search"]),
        _createVNode("span", {
          class: "flex items-center cursor-pointer",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isFiltersPanelOpen = !_ctx.isFiltersPanelOpen))
        }, [
          _createVNode(_component_icon_filter, { class: "w-10 h-10 ml-f8" }),
          _createVNode("span", _hoisted_4, _toDisplayString(_ctx.useTranslations('filters.title')), 1)
        ])
      ])
    ]),
    _createVNode(_component_c_side_panel_with_background, {
      "is-visible": _ctx.isFiltersPanelOpen,
      title: _ctx.useTranslations('filters.title'),
      onClosePanel: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isFiltersPanelOpen=false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_c_issues_filters_panel)
      ]),
      _: 1
    }, 8, ["is-visible", "title"])
  ], 64))
}