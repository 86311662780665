
import { computed, ComputedRef, defineComponent, ref, Ref } from 'vue';
import { useGlobals }                       from '@/hooks/use-globals';
import { AddNotePayload, Issue, IssueStatus } from '@/views/issues/issues.types';
import IconBack                      from '@/components/icons/icon-back.component.vue';
import CIssuesFooterButtons                 from '@/views/issues/components/issues-details/components/issue-details-footer-buttons/issue-details-footer-buttons.component.vue';
import {  useIssues, UseIssuesContext }        from '@/hooks/use-issues';
import { useTranslations } from '@/hooks/use-translations';
import CIssueNotes                                          from '@/views/issues/components/issues-details/components/issue-details-notes/issue-details-notes.component.vue';
import CIssueDescription                                    from '@/views/issues/components/issues-details/components/issue-details-description/issue-details-description.component.vue';
import CModal                                               from '@/components/common/modal/modal.component.vue';
import CAddIssueNoteForm                                    from '@/views/controller/components/add-issue-note-form.component.vue';
import { useModal }                                         from '@/components/common/modal/use-modal';

enum DetailsState {
  DESCRIPTION = 'DESCRIPTION',
  NOTES = 'NOTES'
}

export default defineComponent({
  name: 'CControllerIssuesDetails',
  components: { CAddIssueNoteForm, CModal, CIssueDescription, CIssueNotes, CIssuesFooterButtons, IconBack },
  emits: [ 'decline', 'accept', 'close-panel' ],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const { addNoteAndChangeStatus, changeStatusAndGetAllIssues } = useIssues(UseIssuesContext.CONTROLLER);
    const { closeModal, openModal, isModalVisible } = useModal()
    const issueDetailsState: Ref<DetailsState> = ref(DetailsState.NOTES);
    const issue: ComputedRef<Issue> = computed(() => store.state.issues.selectedIssue);

    const dynamicComponentProps: ComputedRef<{ issueData: Issue } | {uuid: string}> = computed(() =>
      issueDetailsState.value === DetailsState.NOTES ? { ['issueData'] :issue.value } : { uuid: issue.value.uuid }
    )

    const setIssueDetailsState = (state: DetailsState): void => {
      issueDetailsState.value = state
    }

    const handleAddNote = async (payload: AddNotePayload) => {
      await addNoteAndChangeStatus(payload);
      emit('close-panel')
    }

    return {
      setIssueDetailsState,
      IssueStatus,
      changeStatusAndGetAllIssues,
      addNoteAndChangeStatus,
      handleAddNote,
      issueDetailsState,
      dynamicComponentProps,
      isModalVisible,
      closeModal,
      openModal,
      DetailsState,
      useTranslations,
      issue,
    };
  },
});
