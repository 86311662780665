import { reactive, computed, ComputedRef } from 'vue';
import { useGlobals }                      from '@/hooks/use-globals';
import { useTranslations }                 from '@/hooks/use-translations';
import { OptionDropdownElement, useApplicationHeader } from '@/components/application-header/use-application-header';
import { Issue, IssueStatus } from '@/views/issues/issues.types';
import { name as vIssues }                 from '@/views/issues/issues.route';
import { useIssues, UseIssuesContext }     from '@/hooks/use-issues';
import { UserRole } from '@/store/store.types';

export interface useIssueDropdown {
  issueDropdown: ComputedRef<OptionDropdownElement[]>;
}

export const useIssueDropdown = (): { issueDropdown: ComputedRef<OptionDropdownElement[]>; isCreator: ComputedRef<boolean> } => {
  const { store, router } = useGlobals();
  const { currentUserRole, currentUserUuid } = useApplicationHeader();
  const { changeStatusAndGetAllIssues, deleteIssueAndGetAllIssues } = useIssues(UseIssuesContext.ISSUES)
  const issue: ComputedRef<Issue> = computed(() => store.state.issues.selectedIssue);

  const isCreator = computed(() => currentUserUuid === issue.value.issueCreator.uuid || currentUserRole.value === UserRole.ADMIN)

  const issueDropdown: ComputedRef<OptionDropdownElement[]> = computed(() => {
    return generateOptions(isCreator)
  })

  const editIssue = async (): Promise<void> => {
    store.commit('views/vIssues/setEditMode', true);
  };

  const moveToStatus = async (status: string): Promise<void> => {
    store.commit('issues/changeListIssueStatus', { issueUuid: issue.value.uuid, status: status });
    await changeStatusAndGetAllIssues({ issueStatus: (status as unknown as IssueStatus), uuid: issue.value.uuid as unknown as string })
  }

  const deleteIssueAndGoToIssueView = async (): Promise<void> => {
    await deleteIssueAndGetAllIssues(issue.value.uuid)
    await router.push({ name: vIssues });
    store.commit('views/vIssues/closeSidePanel');
    //TODO add router (?) to all issues
  };

  const generateOptions = (isCreator: ComputedRef<boolean>) => {

    const userOption = []

    userOption.push(
      {
        name: useTranslations('optionsIssue.edit'),
        onClick: editIssue,
      }
    )

    switch(issue.value.issueStatus){
      case 'DONE':
        userOption.push(
          {
            name: useTranslations('optionsIssue.moveToInProgress'),
            onClick:() => moveToStatus('IN_PROGRESS'),
          }
        )
        break;

      case 'IN_PROGRESS':
        userOption.push(
          {
            name: useTranslations('optionsIssue.moveToAssigned'),
            onClick:() => moveToStatus('ASSIGNED'),
          }
        )
        break;
    }

    if(isCreator.value){
      userOption.push(
        {
          name: useTranslations('optionsIssue.delete'),
          onClick: deleteIssueAndGoToIssueView,
        }
      )
    }

    return userOption
  }

  return {
    issueDropdown,
    isCreator,
  };
};
