
import { computed, ComputedRef, defineComponent } from 'vue';
import { IssueStatus } from '@/views/issues/issues.types';
import { useGlobals } from '@/hooks/use-globals';
import { User } from '@/views/administration/views/administration-users/administration-users.types';
import CPagination from '@/components/common/pagination/pagination.component.vue';
import { USERS_PAGINATION } from '@/views/issues/helpers/variables';

export default defineComponent({
  name: 'CIssueDetailsUserList',
  components: { CPagination },
  emits: ['set-user'],
  setup() {
    const { store } = useGlobals();
    const state = store.state.issues;
    const selectedUser: ComputedRef<User> = computed(() => state.selectedIssue.hotelUser);
    const users: ComputedRef<User[]> = computed(() => state.users.filter((user:User) => user.uuid !== selectedUser.value.uuid));
    const getUsers = (): void => {
      store.dispatch('issues/getAllUsers');
    }
    getUsers();
    return {
      users,
      getUsers,
      IssueStatus,
      USERS_PAGINATION,
    };
  },
});
