
import { defineComponent, PropType } from 'vue';
import { useDeviceSize } from '@/hooks/use-page-size';
import {
  useGlobalTranslations,
  useTranslations,
} from '@/hooks/use-translations';
import CFormCheckbox from '@/components/common/form/form-checkbox/form-checkbox.component.vue';
import CFormErrorIcon from '@/components/common/form/form-error-icon/form-error-icon.component.vue';

export default defineComponent({
  name: 'CFormCheckboxGroup',
  components: {
    CFormErrorIcon,
    CFormCheckbox,
  },
  props: {
    group: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    translateTagLabel: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { isMobile } = useDeviceSize();

    const handleChangeCheckboxState = (value: string) => {
      const set = new Set(props.modelValue);

      set.has(value) ? set.delete(value) : set.add(value);

      emit('update:modelValue', [...set]);
    };

    return {
      isMobile,
      useGlobalTranslations,
      useTranslations,
      handleChangeCheckboxState,
    };
  },
});
