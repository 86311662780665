import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center bg-primary-500 text-white rounded-xl p-f5"
}
const _hoisted_2 = {
  key: 1,
  class: "text-center bg-blue-light text-white rounded-xl p-f5"
}
const _hoisted_3 = {
  key: 2,
  class: "text-center bg-gray-icon text-white rounded-xl p-f5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.issueStatus === _ctx.IssueStatus.TODO)
    ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.useTranslations('toDo')), 1))
    : (_ctx.issueStatus === _ctx.IssueStatus.IN_PROGRESS)
      ? (_openBlock(), _createBlock("div", _hoisted_2, _toDisplayString(_ctx.useTranslations('inProgress')), 1))
      : (_ctx.issueStatus === _ctx.IssueStatus.DONE)
        ? (_openBlock(), _createBlock("div", _hoisted_3, _toDisplayString(_ctx.useTranslations('done')), 1))
        : _createCommentVNode("", true)
}