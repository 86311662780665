
import { computed, defineComponent, PropType, ref, Ref, ComputedRef } from 'vue';
import useVuelidate, { ValidationRuleWithoutParams }                    from '@vuelidate/core';
import { required }                                                   from '@vuelidate/validators';
import { ConfirmationStatement }                                      from '@/types';
import {  useTranslations } from '@/hooks/use-translations'
import CCloseButton                                                   from '@/components/common/close-button/close-button.component.vue';
import CFormInput                                                     from '@/components/common/form/form-input/form-input.component.vue';
import CButton                                                        from '@/components/common/button/button.component.vue';

interface ModalContent {
  title: string,
  buttonLabel: string,
}

type ValidationRules = {
  configurationName: {required: ValidationRuleWithoutParams}
}

export default defineComponent({
  name: 'CFiltersConfirmationModal',
  components: { CButton, CFormInput, CCloseButton },
  props: {
    confirmationState: {
      type: String as PropType<ConfirmationStatement>,
      required: true
    },

  },
  emits: ['close-modal', 'remove-configuration', 'save-configuration'],
  setup(props, { emit }) {
    const configurationName: Ref<string> = ref('');

    const rules: ComputedRef<ValidationRules> = computed(() => (
      { configurationName: { required } }));

    const modalContent: ComputedRef<ModalContent> = computed(() => {
      return props.confirmationState === ConfirmationStatement.SAVE_ITEM_CONFIRMATION
        ? { title: 'titles.saveFilterConfiguration', buttonLabel: 'button.saveConfiguration' }
        : { title: 'titles.removeFilterConfiguration', buttonLabel: 'button.removeConfiguration' }
    });

    const validator = useVuelidate(rules, { configurationName });

    const handleConfirmationAction = (): void=> {
      props.confirmationState === ConfirmationStatement.SAVE_ITEM_CONFIRMATION
        ? validateInputAndEmitData()
        : emit('remove-configuration')
    };

    const validateInputAndEmitData = (): void => {
      validator.value.$touch()
      !validator.value.$error && emit('save-configuration', configurationName.value)
    };

    return {
      configurationName,
      modalContent,
      useTranslations,
      handleConfirmationAction,
      ConfirmationStatement,
      validator
    }
  }
})
