import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_controller_issues_list = _resolveComponent("c-controller-issues-list")
  const _component_c_main_content = _resolveComponent("c-main-content")
  const _component_c_controller_issues_table = _resolveComponent("c-controller-issues-table")
  const _directive_loading = _resolveDirective("loading")

  return (_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_c_main_content, {
        key: 0,
        class: "h-full"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_c_controller_issues_list)
        ]),
        _: 1
      }))
    : _withDirectives((_openBlock(), _createBlock(_component_c_main_content, {
        key: 1,
        class: "max-h-mainContent"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_c_controller_issues_table)
        ]),
        _: 1
      }, 512)), [
        [_directive_loading, _ctx.loading]
      ])
}