import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white flex w-full h-96 flex-col justify-between rounded-xl p-f21" }
const _hoisted_2 = { class: "w-full flex justify-between border-b border-gray-default " }
const _hoisted_3 = { class: "text-primary-default font-bold" }
const _hoisted_4 = { class: "flex justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_close_button = _resolveComponent("c-close-button")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h2", _hoisted_3, _toDisplayString(_ctx.useGlobalTranslations('addNoteTitle')), 1),
      _createVNode(_component_c_close_button, { onClick: _ctx.closeForm }, null, 8, ["onClick"])
    ]),
    _withDirectives(_createVNode("textarea", {
      ref: "noteHTMLElement",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.noteValue = $event)),
      class: "bg-gray-200 text-12 w-full h-1/2 p-f3 outline-none resize-none",
      tabindex: "0"
    }, null, 512), [
      [_vModelText, _ctx.noteValue]
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_c_button, {
        "button-label": _ctx.useGlobalTranslations('button.addNewNote'),
        onClick: _ctx.addNewNote
      }, null, 8, ["button-label", "onClick"]),
      _createVNode(_component_c_button, {
        is: "red",
        "button-label": _ctx.useGlobalTranslations('button.cancel'),
        type: "button",
        onClick: _ctx.closeForm
      }, null, 8, ["button-label", "onClick"])
    ])
  ]))
}