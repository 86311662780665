
import { defineComponent, PropType } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import CTag from '@/components/common/tag/tag.component.vue';
import { tagTypes } from '@/types';


export default defineComponent({
  name: 'CFormCheckbox',
  components: {
    CTag,
  },
  props: {
    name: {
      type: String,
      default: tagTypes.OTHER,
    },
    color: {
      type: String,
      default: '#06D6A0',
    },
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    firstElement: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {

    const changeInputValue = (value: string): void => {
      const setOfCheckboxes = new Set(props.modelValue);

      setOfCheckboxes.has(value)
        ? setOfCheckboxes.delete(value)
        : setOfCheckboxes.add(value);

      emit('update:modelValue', [...setOfCheckboxes])
    };


    return {
      useGlobalTranslations,
      changeInputValue,
    };
  },
});
