import { ColouredPriority, ColouredStatus, Issue, IssueNote, IssueStatus, ListIssue } from '@/views/issues/issues.types';
import { TableColumn }                                                                from '@/components/common/table/table.types';

export interface State {
tableColumns: TableColumn[],

}

export enum ListItemMovementState {
    DEFAULT_POSITION = 'DEFAULT_POSITION',
    MOVING_LEFT = 'MOVING_LEFT',
    MOVING_RIGHT = 'MOVING_RIGHT',
    MOVED_LEFT = 'MOVED_LEFT'
}

export interface ChangeIssueStatusPayload {
    uuid: string,
    issueStatus: IssueStatus
}