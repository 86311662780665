import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center my-f8 text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_checkbox_group = _resolveComponent("c-form-checkbox-group")
  const _component_c_custom_switch = _resolveComponent("c-custom-switch")
  const _component_c_form_multi_select = _resolveComponent("c-form-multi-select")
  const _component_c_form_date_picker = _resolveComponent("c-form-date-picker")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_c_form_checkbox_group, {
      group: _ctx.priorities,
      label: _ctx.useTranslations('filters.priority'),
      "model-value": _ctx.model.priorities,
      "translate-tag-label": true,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'priorities', value: $event })
    ))
    }, null, 8, ["group", "label", "model-value"]),
    (_ctx.isMobile || _ctx.viewMode !== _ctx.ViewModes.LIST)
      ? (_openBlock(), _createBlock(_component_c_form_checkbox_group, {
          key: 0,
          group: _ctx.statuses,
          label: _ctx.useTranslations('filters.status'),
          "model-value": _ctx.model.statuses,
          "translate-tag-label": true,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'statuses', value: $event })
    ))
        }, null, 8, ["group", "label", "model-value"]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_1, [
      _createVNode("span", null, _toDisplayString(_ctx.useTranslations("filters.overdue")), 1),
      _createVNode(_component_c_custom_switch, {
        "model-value": _ctx.model.deadlineOver,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (
        _ctx.changeFiltersConfiguration({ key: 'deadlineOver', value: $event })
      ))
      }, null, 8, ["model-value"])
    ]),
    _createVNode(_component_c_form_multi_select, {
      items: _ctx.options.issueTypes,
      label: _ctx.useTranslations('filters.issuesTypes'),
      "model-value": _ctx.model.issueTypes,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'issueTypes', value: $event })
    ))
    }, null, 8, ["items", "label", "model-value"]),
    (_ctx.assignFilter !== _ctx.IssueFilterType.GROUP)
      ? (_openBlock(), _createBlock(_component_c_form_checkbox_group, {
          key: 1,
          group: _ctx.options.groups,
          label: _ctx.useTranslations('filters.groups'),
          "model-value": _ctx.model.otherGroups,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'otherGroups', value: $event })
    ))
        }, null, 8, ["group", "label", "model-value"]))
      : _createCommentVNode("", true),
    _createVNode(_component_c_form_multi_select, {
      items: _ctx.options.guests,
      label: _ctx.useTranslations('filters.guests'),
      "model-value": _ctx.model.guests,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'guests', value: $event })
    ))
    }, null, 8, ["items", "label", "model-value"]),
    _createVNode(_component_c_form_multi_select, {
      items: _ctx.options.locations,
      label: _ctx.useTranslations('filters.locations'),
      "model-value": _ctx.model.locations,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'locations', value: $event })
    ))
    }, null, 8, ["items", "label", "model-value"]),
    (_ctx.assignFilter !== _ctx.IssueFilterType.ASSIGNED)
      ? (_openBlock(), _createBlock(_component_c_form_multi_select, {
          key: 2,
          items: _ctx.options.assignedUsers,
          label: _ctx.useTranslations('filters.assignedTo'),
          "model-value": _ctx.model.assignedUsers,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'assignedUsers', value: $event })
    ))
        }, null, 8, ["items", "label", "model-value"]))
      : _createCommentVNode("", true),
    _createVNode(_component_c_form_multi_select, {
      items: _ctx.options.createdByUsers,
      label: _ctx.useTranslations('filters.createdBy'),
      "model-value": _ctx.model.createdByUsers,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (
      _ctx.changeFiltersConfiguration({ key: 'createdByUsers', value: $event })
    ))
    }, null, 8, ["items", "label", "model-value"]),
    (!_ctx.isNearDeadline)
      ? (_openBlock(), _createBlock(_component_c_form_date_picker, {
          key: 3,
          label: _ctx.useTranslations('filters.deadlineFrom'),
          "model-value": _ctx.model.dateRange.min,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.checkDeadlineDate({ min: $event })))
        }, null, 8, ["label", "model-value"]))
      : _createCommentVNode("", true),
    (!_ctx.isNearDeadline)
      ? (_openBlock(), _createBlock(_component_c_form_date_picker, {
          key: 4,
          label: _ctx.useTranslations('filters.deadlineTo'),
          "model-value": _ctx.model.dateRange.max,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.checkDeadlineDate({ max: $event })))
        }, null, 8, ["label", "model-value"]))
      : _createCommentVNode("", true),
    (!_ctx.isNearDeadline)
      ? (_openBlock(), _createBlock(_component_c_form_date_picker, {
          key: 5,
          label: _ctx.useTranslations('filters.createdFrom'),
          "model-value": _ctx.model.createdDateRange.min,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_ctx.checkCreatedDate({ min: $event })))
        }, null, 8, ["label", "model-value"]))
      : _createCommentVNode("", true),
    (!_ctx.isNearDeadline)
      ? (_openBlock(), _createBlock(_component_c_form_date_picker, {
          key: 6,
          label: _ctx.useTranslations('filters.createdTo'),
          "model-value": _ctx.model.createdDateRange.max,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_ctx.checkCreatedDate({ max: $event })))
        }, null, 8, ["label", "model-value"]))
      : _createCommentVNode("", true),
    _createVNode(_component_c_button, {
      is: "light",
      class: "self-end !w-24 mt-f21",
      onClick: _ctx.clearAllFilters
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.useTranslations("clearAllFilters")), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}