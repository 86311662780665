
import { computed, ComputedRef, defineComponent, PropType, WritableComputedRef } from 'vue';
import { useGlobalTranslations }                                                 from '@/hooks/use-translations';
import CFormTextarea                                                             from '@/components/common/form/form-textarea/form-textarea.component.vue';
import CButton                                                                   from '@/components/common/button/button.component.vue';
import { useGlobals }                                                            from '@/hooks/use-globals';



export default defineComponent({
  name: 'CIssueInput',
  components: { CButton, CFormTextarea },
  props: {
    description: {
      type: String,
      default: '',
    },
    files: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },

  },
  emits: [ 'update:description', 'update:files', 'click', 'keypress' ],
  setup(props, { emit }) {
    const { store } = useGlobals();
    const descriptionInputValue: WritableComputedRef<string> = computed({
      get: () => props.description,
      set: (value) => emit('update:description', value),
    });

    const changeDescriptionValue = (e: InputEvent) => {
      descriptionInputValue.value = (e.target as HTMLInputElement).value;
    };

    const onUpload = (e: InputEvent) => {
      emit('update:files', (e.target as HTMLInputElement).files);
    };

    const isSendButtonDisabled: ComputedRef<boolean> = computed(() => store.state.issues.loading);

    return {
      onUpload,
      changeDescriptionValue,
      useGlobalTranslations,
      isSendButtonDisabled,
      descriptionInputValue,
    };
  },
});
