import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: ["text-gray-400 shadow-around rounded-full h-14 w-14 p-f8 flex items-center justify-center", _ctx.disabled ? 'hover:text-gray-400' : _ctx.classList],
    disabled: _ctx.disabled,
    title: _ctx.disabled ? _ctx.buttonDisabledTitle : _ctx.buttonActiveTitle,
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click')))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "transform transform-none" }))
  ], 10, ["disabled", "title"]))
}