import { Module }    from 'vuex';
import { RootState } from '@/store/store.types';
import { State }     from '@/views/controller/controller.types';
import { columnTypes } from '@/components/common/table/table.types';



const store: Module<State, RootState> = {
  namespaced: true,
  state: {
    tableColumns: [
      { name: 'uuid', type: columnTypes.CHECKBOX },
      { name: 'priority', type: columnTypes.TAGS },
      { name: 'name', type: columnTypes.TEXT },
      { name: 'status', type: columnTypes.TAGS },
      { name: 'created', type: columnTypes.TEXT },
      { name: 'deadline', type: columnTypes.TEXT },
      { name: 'location', type: columnTypes.TEXT },
      { name: 'issueNumber', type: columnTypes.TEXT },
      { name: 'actions', type: columnTypes.ACTIONS },
    ],
  }

}

export default store