
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { IssueStatus, ListIssue } from '@/views/issues/issues.types';
import IconFlag from '@/components/icons/icon-flag.component.vue';
import IconPerson from '@/components/icons/icon-person.component.vue';
import { useDeviceSize } from '@/hooks/use-page-size';

export default defineComponent({
  name: 'CIssuesListItem',
  components: { IconPerson, IconFlag },
  props: {
    issue: {
      type: Object as PropType<ListIssue>,
      required: true,
    },
  },
  emits: ['click', 'touchstart', 'touchend', 'touchmove'],
  setup(props) {
    const { isMobile } = useDeviceSize();
    //TODO change priority to one from backend when it will be corrected
    const issuePriority: ComputedRef<string> = computed(() => props.issue.priority);

    return {
      isMobile,
      IssueStatus,
      useGlobalTranslations,
      issuePriority,
    };
  },
});
