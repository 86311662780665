import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative flex flex-col min-h-0 h-full" }
const _hoisted_2 = {
  key: 0,
  class: "flex absolute z-0 w-full top-1/2 transform -translate-y-1/2 justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issues_list_item = _resolveComponent("c-issues-list-item")
  const _component_icon_discard = _resolveComponent("icon-discard")
  const _component_icon_accept = _resolveComponent("icon-accept")
  const _component_c_add_issue_note_form = _resolveComponent("c-add-issue-note-form")
  const _component_c_modal = _resolveComponent("c-modal")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_c_issues_list_item, {
        ref: "listItem",
        class: ["transition-transform relative z-10", _ctx.setActiveListComponentClass],
        issue: _ctx.issue,
        onClick: _ctx.handleSettingSingleIssue,
        onTouchend: _ctx.finishMovementAction,
        onTouchmove: _ctx.moveComponent,
        onTouchstart: _ctx.initiateMovementAction
      }, null, 8, ["class", "issue", "onClick", "onTouchend", "onTouchmove", "onTouchstart"]),
      (_ctx.componentPosition !== _ctx.ListItemMovementState.DEFAULT_POSITION)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("button", {
              class: "p-f8 bg-error mr-f13 rounded-xl",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
            }, [
              _createVNode(_component_icon_discard, { class: "text-white w-10 h-10" })
            ]),
            _createVNode("button", {
              class: "p-f8 bg-primary-default rounded-xl",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeStatusAndGetAllIssues({uuid: _ctx.issue.uuid, issueStatus: _ctx.IssueStatus.ENDED})))
            }, [
              _createVNode(_component_icon_accept, { class: "text-white w-10 h-10" })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_c_modal, { "is-open": _ctx.isModalVisible }, {
      default: _withCtx(() => [
        _createVNode(_component_c_add_issue_note_form, {
          "issue-uuid": _ctx.issue.uuid,
          onAddNewNote: _ctx.addNoteAndChangeStatus,
          onCloseForm: _ctx.closeModal
        }, null, 8, ["issue-uuid", "onAddNewNote", "onCloseForm"])
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}