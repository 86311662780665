
import { defineComponent, PropType } from 'vue';
import { IssueStatus }               from '@/views/issues/issues.types';
import { useTranslations }           from '@/hooks/use-translations';

export default defineComponent({
  name: 'CIssuesListTitle',
  props: {
    issueStatus: {
      type:String as PropType<IssueStatus>,
      required: true,
    }
  },
  setup() {
    return {
      IssueStatus,
      useTranslations,
    }
  }
});
