
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';
import { useDeviceSize } from '@/hooks/use-page-size';
import { name as vIssues } from '@/views/issues/issues.route';
import CIssuesHeaderPanel from '@/views/issues/components/issues-header-panel/issues-header-panel.component.vue';
import CIssuesSelectedFilters
  from '@/views/issues/components/issues-selected-filters/issues-selected-filters.component.vue';
import CIssuesListDesktop from '@/views/issues/components/issues-list-desktop/issues-list-desktop.component.vue';
import CIssuesListMobile from '@/views/issues/components/issues-list-mobile/issues-list-mobile.component.vue';
import VIssueDetails from '@/views/issues/components/issues-details/issue-details.view.vue';
import CIssuesTable from '@/views/issues/components/issues-table/issues-table.component.vue';
import CIssueEditForm from '@/views/issues/components/issues-edit/issue-edit.component.vue';
import CAddTaskButtonGroup from '@/components/common/add-task-button/add-task-button-group.component.vue';
import CMainContent from '@/components/main-content/main-content.component.vue';
import CSidePanelInView from '@/components/common/side-panel-in-view/side-panel-in-view.component.vue';
import { getViewStorePath } from '@/store/store.helpers';
import { IssueFilterType, IssueMainType } from '@/store/store.types';
import { ListIssue, ViewModes } from '@/views/issues/issues.types';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { onBeforeRouteLeave } from 'vue-router';
import { MainType } from '@/types';

export default defineComponent({
  name: 'VIssues',
  components: {
    CIssuesTable,
    CIssueEditForm,
    CSidePanelInView,
    VIssueDetails,
    CAddTaskButtonGroup,
    CIssuesListMobile,
    CIssuesListDesktop,
    CIssuesSelectedFilters,
    CIssuesHeaderPanel,
    CMainContent,
  },
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { getAllIssues, getFiltersConfiguration } = useIssues(UseIssuesContext.ISSUES);
    const state = store.state.views.vIssues;
    const mode: ComputedRef<ViewModes> = computed(() => state.viewMode);
    const issues: ComputedRef<ListIssue[]> = computed(() => state.store.issues.issues);
    const areIssuesLoading: ComputedRef<boolean> = computed(() => store.state.issues.loading);
    const isSidePanelVisible: ComputedRef<boolean> = computed(() => state.isSidePanelVisible);
    const isEditMode: ComputedRef<boolean> = computed(() => store.state.views.vIssues.isEditMode);
    const issuesParamsType: ComputedRef<MainType> = computed(() => store.state.issuesParams.type);

    !store.state.issuesParams.filter && store.commit('setIssueParamsFilter', IssueFilterType.ASSIGNED);


    getFiltersConfiguration({ filterType: issuesParamsType.value });
    getAllIssues();
    store.dispatch('issues/getIssueOptions', { filterType: issuesParamsType.value })

    onBeforeRouteLeave((): void => {
      isSidePanelVisible.value && closeSidePanel()
    })


    const closeSidePanel = (): void => {
      store.commit(`${getViewStorePath(vIssues)}/closeSidePanel`);
    };

    return {
      mode,
      isSidePanelVisible,
      isMobile,
      isEditMode,
      areIssuesLoading,
      issues,
      issuesParamsType,
      ViewModes,
      IssueMainType,
      closeSidePanel,
      useTranslations,
    };
  },
});
