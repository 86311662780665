
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { useTranslations, useGlobalTranslations }                                  from '@/hooks/use-translations';
import IconArrowDown                                        from '@/components/icons/icon-arrow-down.component.vue';
import CButton                                              from '@/components/common/button/button.component.vue';
import { IssueStatus }                                      from '@/views/issues/issues.types';
import IconChangeUser                                       from '@/components/icons/icon-change-user.component.vue';
import { ASSIGN_TO_ME, END_ISSUE, RESTORE, START_ISSUE }    from '@/views/issues/helpers/variables';
import IconClose                                            from '@/components/icons/icon-close.component.vue';
import IconCheck                                            from '@/components/icons/icon-check.component.vue';
import { useGlobals }                                       from '@/hooks/use-globals';

export default defineComponent({
  name: 'CIssueDetailsFooterButtons',
  components: { IconCheck, IconClose, IconArrowDown, CButton },
  props: {
    status: {
      type: String as PropType<IssueStatus>,
      required: true,
    },
    showNavigation: {
      type: Boolean,
      default: false,
    },
    controllerUuid: {
      type: String,
      default: null,
    },
  },
  emits: [ 'change-user', 'change-status', 'navigate-to-next', 'navigate-to-previous', 'accept', 'decline' ],
  setup() {
    const { store } = useGlobals();
    const isController: ComputedRef<boolean> = computed(() => store.state.user.isController);
    const userUuid: ComputedRef<string> = computed(() => store.state.user.uuid);
    const getTranslationLabel = (status: IssueStatus): string => {
      switch (status) {
        case IssueStatus.ASSIGNED:
          return START_ISSUE;
        case IssueStatus.IN_PROGRESS:
          return END_ISSUE;
        case IssueStatus.DONE:
          return RESTORE;
        case IssueStatus.NEW :
          return ASSIGN_TO_ME;
        case IssueStatus.ENDED:
          return RESTORE;
        default:
          return START_ISSUE;
      }
    };
    return {
      isController,
      getTranslationLabel,
      useTranslations,
      IssueStatus,
      userUuid,
      useGlobalTranslations
    };
  },
});
