
import { computed, ComputedRef, defineComponent, reactive, Ref, ref } from 'vue';
import useVuelidate, { ValidationArgs } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useGlobals } from '@/hooks/use-globals';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import { EditIssueFormRules, Issue, IssueRelatedIssues, ModifyIssue } from '@/views/issues/issues.types';
import { FormMode } from '@/views/administration/administration.types';
import { modifyIssueToPutPayload } from '@/views/issues/helpers/helpers';
import CIssueEditFormStepOne
  from '@/views/issues/components/issues-edit/issues-edit-steps/issues-edit-step-one.component.vue';
import CIssueEditFormStepTwo
  from '@/views/issues/components/issues-edit/issues-edit-steps/issues-edit-step-two.component.vue';
import CFormSteps from '@/components/form-steps/form-steps.component.vue';
import { useDeviceSize } from '@/hooks/use-page-size';
import CButtonBack from '@/components/common/button/button-back.component.vue';
import CButton from '@/components/common/button/button.component.vue';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import CSidePanelWithBackground
  from '@/components/common/side-panel-with-background/side-panel-with-background.component.vue';
import CLinkedIssues from '@/views/issues/components/issues-edit/linked-issues/linked-issues.component.vue';

export default defineComponent({
  name: 'CIssueDetailsEditForm',
  components: {
    CLinkedIssues,
    CSidePanelWithBackground,
    CButton,
    CButtonBack,
    CFormSteps,
    CIssueEditFormStepTwo,
    CIssueEditFormStepOne
  },
  emits: ['close-panel'],
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { editIssueAndGetAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const selectedIssue: Issue = store.state.issues.selectedIssue;
    const activeStep = ref(1);
    const isSidePanelOpen = ref(false);
    const formData = reactive(store.getters['issues/getEditFormSelectedIssue']);
    const relatedIssues: Ref<IssueRelatedIssues[]> = ref(selectedIssue.relatedIssues);
    const rules: ComputedRef<EditIssueFormRules> = computed(() => {
      return {
        name: { required },
        priority: { required },
        locationUuid: { required },
        groupUuid: { required },
        issueTypeUuid: { required },
        controllerUuid: { required }
      };
    });

    const toggleSidePanel = (payload: boolean) => {
      isSidePanelOpen.value = payload;
    };

    const goToStep = (index: number) => {
      if (index === activeStep.value) {
        store.commit('views/vIssues/setEditMode', false);
        return;
      }
      activeStep.value = index;
    };

    const addRelatedIssues = (linkedIssues: IssueRelatedIssues[]) => {
      relatedIssues.value = linkedIssues;
      toggleSidePanel(false);
    };

    const v$ = useVuelidate((rules as unknown) as ValidationArgs, formData as any);
    const handleSubmit = async (): Promise<void> => {
      v$.value.$touch();
      if (!v$.value.$error) {
        try {
          store.commit('views/vIssues/closeSidePanel');
          formData.relatedIssues = relatedIssues.value.map((issue: IssueRelatedIssues) => issue.uuid);
          await editIssueAndGetAllIssues({ issueUuid: selectedIssue.uuid, modifyIssueData: formData });
          activeStep.value = 1;
        } catch (e) {
          throw Error(e);
        }
      }
    };

    return {
      isMobile,
      formData,
      v$,
      FormMode,
      goToStep,
      activeStep,
      handleSubmit,
      toggleSidePanel,
      isSidePanelOpen,
      relatedIssues,
      addRelatedIssues,
      useTranslations,
      useGlobalTranslations,
    };
  },
});
