
import { computed, defineComponent, ref } from 'vue';
import CTable                           from '@/components/common/table/table.component.vue';
import { useGlobals }                                  from '@/hooks/use-globals';
import CModal                                          from '@/components/common/modal/modal.component.vue';
import CAddIssueNoteForm             from '@/views/controller/components/add-issue-note-form.component.vue';
import { AddNotePayload, IssueStatus } from '@/views/issues/issues.types';
import { useTranslations }           from '@/hooks/use-translations';
import CSidePanel                                      from '@/components/common/side-panel/side-panel.component.vue';
import CControllerIssuesDetails                        from '@/views/controller/components/controller-issues-details.component.vue';
import {  useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { useModal }                                    from '@/components/common/modal/use-modal';
import { useSidePanel }                                from '@/components/common/side-panel/use-side-panel';

export default defineComponent({
  name: 'CControllerIssuesTable',
  components: { CControllerIssuesDetails, CSidePanel, CAddIssueNoteForm, CModal, CTable },
  setup() {
    const { store } = useGlobals();
    const columns = store.state.issues.tableColumns;
    const controllerIssues = computed(() => store.getters[ 'issues/controllerTableData' ]);
    const { closeModal, isModalVisible, openModal } = useModal()
    const { isSidePanelVisible, openSidePanel, closeSidePanel } = useSidePanel()
    const { addNoteAndChangeStatus,
      changeStatusAndGetAllIssues,
      getSelectedIssue } = useIssues(UseIssuesContext.CONTROLLER);
    const issueUuid = ref('');

    const showSelectedIssue = async (uuid: string) => {
      await getSelectedIssue(uuid);
      openSidePanel()
    };
    const handleDataEvent = (uuid: string, name: string) => {
      switch (name) {
        case 'decline':
          issueUuid.value = uuid;
          openModal()
          break;
        case 'accept':
          changeStatusAndGetAllIssues({ uuid, issueStatus: IssueStatus.ENDED });
          break;
      }
    };

    const declineIssue = async (payload: AddNotePayload) => {
      await addNoteAndChangeStatus(payload);
      closeSidePanel()
    }
    return {
      issueUuid,
      isSidePanelVisible,
      columns,
      controllerIssues,
      closeSidePanel,
      closeModal,
      isModalVisible,
      useTranslations,
      showSelectedIssue,
      handleDataEvent,
      declineIssue
    };
  },
});
