import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col min-h-0 gap-3 sm:gap-6" }
const _hoisted_2 = { class: "flex w-full justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_custom_switch = _resolveComponent("c-custom-switch")
  const _component_c_issue_note = _resolveComponent("c-issue-note")
  const _component_c_issue_note_image_preview = _resolveComponent("c-issue-note-image-preview")
  const _component_c_issue_input = _resolveComponent("c-issue-input")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_c_custom_switch, {
        modelValue: _ctx.isHistoryShown,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHistoryShown = $event)),
        class: "text-12 sm:text-14",
        "left-label": _ctx.useTranslations('history'),
        type: _ctx.switchTypes.SINGLE
      }, null, 8, ["modelValue", "left-label", "type"])
    ]),
    _createVNode("div", {
      ref: "issueNotes",
      class: "overflow-y-scroll w-full scrollbar-none flex flex-grow min-h-0 flex-col gap-y-3 h-3/4",
      style: {maxHeight: _ctx.fixedHeight}
    }, [
      _createVNode(_TransitionGroup, {
        "enter-from-class": "transform scale-y-0 opacity-0",
        "enter-to-class": "transform scale-y-1 opacity-100",
        "leave-from-class": "transform opacity-100",
        "leave-to-class": "transform scale-y-0 opacity-0"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.filteredNotes, (note) => {
            return (_openBlock(), _createBlock("div", {
              key: note.uuid,
              class: "transition-all rounded-xl ease-out duration-200 origin-top"
            }, [
              _createVNode(_component_c_issue_note, { note: note }, null, 8, ["note"])
            ]))
          }), 128))
        ]),
        _: 1
      }),
      (_ctx.showImagePreview)
        ? (_openBlock(), _createBlock(_component_c_issue_note_image_preview, {
            key: _ctx.files,
            files: _ctx.files,
            onRemoveImage: _ctx.removeFromPreview,
            onScrollDown: _ctx.scrollDown
          }, null, 8, ["files", "onRemoveImage", "onScrollDown"]))
        : _createCommentVNode("", true)
    ], 4),
    _createVNode(_component_c_issue_input, {
      description: _ctx.description,
      "onUpdate:description": _cache[2] || (_cache[2] = ($event: any) => (_ctx.description = $event)),
      files: _ctx.files,
      "onUpdate:files": _cache[3] || (_cache[3] = ($event: any) => (_ctx.files = $event)),
      onClick: _ctx.addNewNote
    }, null, 8, ["description", "files", "onClick"])
  ]))
}