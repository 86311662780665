import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-12 text-gray-850" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_form_select_new = _resolveComponent("c-form-select-new")
  const _component_c_linked_issues_selected = _resolveComponent("c-linked-issues-selected")
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_form_textarea = _resolveComponent("c-form-textarea")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_c_form_select_new, {
      "model-value": _ctx.formData.groupUuid,
      "onUpdate:model-value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.groupUuid = $event)),
      error: _ctx.v$.groupUuid.$error ? 'required' : null,
      items: _ctx.issueOptions.groups,
      label: _ctx.useTranslations('form.labels.group')
    }, null, 8, ["model-value", "error", "items", "label"]),
    _createVNode(_component_c_form_select_new, {
      "model-value": _ctx.formData.hotelUserUuid,
      "onUpdate:model-value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.hotelUserUuid = $event)),
      items: _ctx.issueOptions.assignedUsers,
      label: _ctx.useTranslations('form.labels.hotelUser')
    }, null, 8, ["model-value", "items", "label"]),
    (_ctx.formData.issueStatus !== _ctx.IssueStatus.ENDED)
      ? (_openBlock(), _createBlock(_component_c_form_select_new, {
          key: 0,
          "model-value": _ctx.formData.controllerUuid,
          "onUpdate:model-value": _cache[3] || (_cache[3] = ($event: any) => (_ctx.formData.controllerUuid = $event)),
          error: _ctx.v$.controllerUuid.$error ? 'required' : null,
          items: _ctx.issueOptions.assignedUsers,
          label: _ctx.useTranslations('form.labels.controller')
        }, null, 8, ["model-value", "error", "items", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isIssueCare)
      ? (_openBlock(), _createBlock(_component_c_form_select_new, {
          key: 1,
          "model-value": _ctx.formData.expectationTypeUuid,
          "onUpdate:model-value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.formData.expectationTypeUuid = $event)),
          items: _ctx.issueOptions.expectationType,
          label: _ctx.useTranslations('form.labels.expectationType')
        }, null, 8, ["model-value", "items", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isIssueCare)
      ? (_openBlock(), _createBlock(_component_c_form_select_new, {
          key: 2,
          "model-value": _ctx.formData.solutionTypeUuid,
          "onUpdate:model-value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.formData.solutionTypeUuid = $event)),
          items: _ctx.issueOptions.solutionType,
          label: _ctx.useTranslations('form.labels.solutionType')
        }, null, 8, ["model-value", "items", "label"]))
      : _createCommentVNode("", true),
    _createVNode("span", _hoisted_1, _toDisplayString(_ctx.useTranslations('form.labels.relatedIssues')), 1),
    _createVNode("button", {
      class: "h-16 w-full border border-gray-icon border-dashed text-12 text-gray-850 text-center leading-10 focus:outline-none",
      onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.$emit('add-task')), ["prevent"]))
    }, _toDisplayString(_ctx.useTranslations('details.addTask')), 1),
    _createVNode(_component_c_linked_issues_selected, {
      details: "",
      "related-issues": _ctx.relatedIssues,
      onUpdateRelated: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update-related', $event)))
    }, null, 8, ["related-issues"]),
    _createVNode(_component_c_form_input, {
      modelValue: _ctx.formData.guestName,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_ctx.formData.guestName = $event)),
      label: _ctx.useTranslations('form.labels.guestName')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_c_form_textarea, {
      modelValue: _ctx.formData.guestDescription,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_ctx.formData.guestDescription = $event)),
      label: _ctx.useTranslations('form.labels.guestDescription')
    }, null, 8, ["modelValue", "label"]),
    (_ctx.isIssueCare)
      ? (_openBlock(), _createBlock(_component_c_form_textarea, {
          key: 3,
          modelValue: _ctx.formData.expectations,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.formData.expectations = $event)),
          label: _ctx.useTranslations('form.labels.expectations')
        }, null, 8, ["modelValue", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isIssueCare)
      ? (_openBlock(), _createBlock(_component_c_form_textarea, {
          key: 4,
          modelValue: _ctx.formData.solution,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.formData.solution = $event)),
          label: _ctx.useTranslations('form.labels.solution')
        }, null, 8, ["modelValue", "label"]))
      : _createCommentVNode("", true)
  ], 64))
}