import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex overflow-x-hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issues_header_panel = _resolveComponent("c-issues-header-panel")
  const _component_c_issues_selected_filters = _resolveComponent("c-issues-selected-filters")
  const _component_c_issues_list_desktop = _resolveComponent("c-issues-list-desktop")
  const _component_c_issues_table = _resolveComponent("c-issues-table")
  const _component_c_add_task_button_group = _resolveComponent("c-add-task-button-group")
  const _component_c_main_content = _resolveComponent("c-main-content")
  const _component_c_issue_edit_form = _resolveComponent("c-issue-edit-form")
  const _component_v_issue_details = _resolveComponent("v-issue-details")
  const _component_c_side_panel_in_view = _resolveComponent("c-side-panel-in-view")
  const _component_c_issues_list_mobile = _resolveComponent("c-issues-list-mobile")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!_ctx.isMobile)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _withDirectives(_createVNode(_component_c_main_content, { class: "max-h-mainContent overflow-hidden" }, {
            default: _withCtx(() => [
              _createVNode(_component_c_issues_header_panel),
              _createVNode(_component_c_issues_selected_filters),
              (_ctx.mode===_ctx.ViewModes.LIST && _ctx.issuesParamsType !== _ctx.IssueMainType.OBSERVED)
                ? (_openBlock(), _createBlock(_component_c_issues_list_desktop, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.mode===_ctx.ViewModes.TABLE || _ctx.issuesParamsType === _ctx.IssueMainType.OBSERVED)
                ? (_openBlock(), _createBlock(_component_c_issues_table, { key: 1 }))
                : _createCommentVNode("", true),
              _createVNode(_component_c_add_task_button_group)
            ]),
            _: 1
          }, 512), [
            [_directive_loading, _ctx.areIssuesLoading]
          ]),
          _createVNode(_component_c_side_panel_in_view, {
            "is-visible": _ctx.isSidePanelVisible,
            title: _ctx.isEditMode ? _ctx.useTranslations('form.title') : _ctx.useTranslations('issueDetails'),
            onClosePanel: _ctx.closeSidePanel
          }, {
            default: _withCtx(() => [
              (_ctx.isEditMode)
                ? (_openBlock(), _createBlock(_component_c_issue_edit_form, { key: 0 }))
                : (_openBlock(), _createBlock(_component_v_issue_details, { key: 1 }))
            ]),
            _: 1
          }, 8, ["is-visible", "title", "onClosePanel"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isSidePanelVisible && _ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_c_main_content, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_c_issues_header_panel),
            _createVNode(_component_c_issues_selected_filters),
            _createVNode(_component_c_issues_list_mobile),
            _createVNode(_component_c_add_task_button_group)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.isMobile && _ctx.isSidePanelVisible && !_ctx.isEditMode)
      ? (_openBlock(), _createBlock(_component_v_issue_details, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.isMobile && _ctx.isEditMode && _ctx.isSidePanelVisible)
      ? (_openBlock(), _createBlock(_component_c_main_content, { key: 3 }, {
          default: _withCtx(() => [
            _createVNode(_component_c_issue_edit_form)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}