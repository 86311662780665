import { Ref, ref } from 'vue';

export interface UseModal {
    isModalVisible: Ref<boolean>;
    openModal: () => void;
    closeModal: () => void;
}
export const useModal = (): UseModal => {
  const isModalVisible: Ref<boolean> = ref(false);

  const openModal = (): void => {
    isModalVisible.value = true;
  }

  const closeModal = (): void => {
    isModalVisible.value = false;
  }
  return {
    isModalVisible,
    openModal,
    closeModal,
  }
}