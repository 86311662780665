
import { computed, defineComponent, PropType, reactive } from 'vue';
import { cloneDeep } from 'lodash-es';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { useDeviceSize } from '@/hooks/use-page-size';
import { useTranslations } from '@/hooks/use-translations';
import { MainType } from '@/types';
import { Notification } from '@/components/notification/notification';
import { IssueFilterType } from '@/store/store.types';
import {
  DateRange,
  FiltersConfiguration,
  IssuePriority,
  IssueStatus,
  SelectedFilters,
  ViewModes,
} from '@/views/issues/issues.types';
import {
  initialFilterState,
  priorities,
  statuses,
} from '@/views/issues/helpers/variables';
import CFormMultiSelect from '@/components/common/form/form-multi-select/form-multi-select.component.vue';
import CFormDatePicker from '@/components/common/form/form-date-picker/form-date-picker.component.vue';
import CFormCheckboxGroup from '@/components/common/form/form-checkbox-group/form-checkbox-group.component.vue';
import CCustomSwitch from '@/components/common/custom-switch/custom-switch.component.vue';
import CButton from '@/components/common/button/button.component.vue';
import { useGlobals } from '@/hooks/use-globals';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';

export default defineComponent({
  name: 'CIssuesFiltersForm',
  components: {
    CButton,
    CCustomSwitch,
    CFormCheckboxGroup,
    CFormDatePicker,
    CFormMultiSelect,
  },
  props: {
    filters: {
      type: Object as PropType<FiltersConfiguration>,
      required: true,
    },
    assignFilter: {
      type: String as PropType<IssueFilterType>,
      required: true,
    },
    createdByFilter: {
      type: String as PropType<IssueFilterType>,
      required: true,
    },
    isNearDeadline: {
      type: Boolean,
      default: false,
    },
    viewMode: {
      type: String as PropType<ViewModes>,
      required: true,
    },
    filterType: {
      type: String as PropType<MainType>,
      required: true,
    },
  },
  setup(props) {
    const { isMobile } = useDeviceSize();
    const { store } = useGlobals();
    const { updateFiltersAndGetAllIssues } = useIssues(UseIssuesContext.ISSUES);
    const filtersConfiguration: FiltersConfiguration = reactive(props.filters);
    const model = computed(() => store.state.issues.selectedIssueFilters);
    const options = computed(() => store.getters['issues/issuesOptions']);

    const checkDeadlineDate = (value: Partial<DateRange>) => {
      const dateRangeMin = value.min ?? model.value.dateRange.min;
      const dateRangeMax = value.max ?? model.value.dateRange.max;

      checkRangeDate(dateRangeMin, dateRangeMax, 'dateRange');
    };

    const checkCreatedDate = (value: Partial<DateRange>) => {
      const dateRangeMin = value.min ?? model.value.createdDateRange.min;
      const dateRangeMax = value.max ?? model.value.createdDateRange.max;

      checkRangeDate(dateRangeMin, dateRangeMax, 'createdDateRange');
    };

    const checkRangeDate = (
      dateRangeMin: string,
      dateRangeMax: string,
      key: keyof Pick<SelectedFilters, 'dateRange' | 'createdDateRange'>
    ) => {
      const isValid =
        !dateRangeMin || !dateRangeMax || dateRangeMin <= dateRangeMax;

      if (isValid) {
        const value = { min: dateRangeMin, max: dateRangeMax };
        changeFiltersConfiguration({ key: key, value });
      } else {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${GLOBAL}.errors.invalidDateRange`)
        );
      }
    };

    const changeFiltersConfiguration = async ({
      key,
      value,
    }: {
      key: keyof Omit<
        SelectedFilters,
        'issueFilterConfigurationUuid' | 'filterType'
      >;
      value: string[] | IssueStatus[] | boolean | IssuePriority[] | DateRange;
    }) => {
      const currentConfiguration: SelectedFilters = cloneDeep(model.value);
      if (key === 'deadlineOver') {
        currentConfiguration[key] = value as boolean;
      } else if (key === 'createdDateRange' || key === 'dateRange') {
        currentConfiguration[key] = {
          ...currentConfiguration[key],
          ...(value as DateRange),
        };
      } else {
        currentConfiguration[key] = value as string[] &
          IssueStatus[] &
          IssuePriority[];
      }
      currentConfiguration.filterType = props.filterType;
      await updateFiltersAndGetAllIssues(currentConfiguration);
    };

    const clearAllFilters = (): void => {
      updateFiltersAndGetAllIssues(cloneDeep(initialFilterState));
    };

    return {
      changeFiltersConfiguration,
      checkDeadlineDate,
      checkCreatedDate,
      filtersConfiguration,
      clearAllFilters,
      IssueFilterType,
      useTranslations,
      priorities,
      statuses,
      ViewModes,
      isMobile,
      options,
      model,
    };
  },
});
