
import { computed, ComputedRef, defineComponent, onUnmounted } from 'vue';
import { useGlobals }                                                  from '@/hooks/use-globals';
import { getViewStorePath }                                   from '@/store/store.helpers';
import { name as vIssues }                                    from '@/views/issues/issues.route';
import { IssueStatus, ListIssue } from '@/views/issues/issues.types';
import { OnDropPayload }                                      from '@/components/common/drag-and-drop/drag-and-drop.types';
import CDragAndDrop                                           from '@/components/common/drag-and-drop/drag-and-drop.component.vue';
import CDropzone                                              from '@/components/common/drag-and-drop/components/dropzone.component.vue';
import CIssuesListTitle                                       from '@/views/issues/components/issues-list-desktop/components/issue-list-title.component.vue';
import CIssuesListItem                                        from '@/views/issues/components/issues-list-item/issues-list-item.component.vue';
import { useGlobalTranslations }                              from '@/hooks/use-translations';
import { useIssues, UseIssuesContext }                        from '@/hooks/use-issues';
import { cloneDeep }                                          from 'lodash-es';
import { initialFilterState }                          from '@/views/issues/helpers/variables';

export default defineComponent({
  name: 'CIssuesListDesktop',
  components: { CDropzone, CDragAndDrop, CIssuesListTitle, CIssuesListItem },
  setup() {
    const { store } = useGlobals();
    const { changeStatusAndGetAllIssues, getSelectedIssue, updateFiltersAndGetAllIssues } = useIssues(UseIssuesContext.ISSUES)
    const issues: ComputedRef<ListIssue[]> = computed(() => store.state.issues.issues);
    const issuesToDo: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesToDo']);
    const issuesInProgress: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesInProgress']);
    const issuesFinished: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesFinished']);

    const updateStatus = (event: OnDropPayload<{ draggedElementItem: string, item: IssueStatus }>): void => {
      store.commit('issues/changeListIssueStatus', { issueUuid: event.draggedElementItem, status: event.item });
      changeStatusAndGetAllIssues({ issueStatus: (event.item as unknown as IssueStatus), uuid: event.draggedElementItem as unknown as string })
    };

    const setSelectedIssue = async (issueUuid: string):Promise<void> => {
      await getSelectedIssue(issueUuid)
      store.commit(`${getViewStorePath(vIssues)}/openSidePanel`);
    };

    onUnmounted(() => {
      store.commit(`${getViewStorePath(vIssues)}/setViewMode`, false)
    })

    updateFiltersAndGetAllIssues(cloneDeep(initialFilterState))

    return {
      useGlobalTranslations,
      issues,
      setSelectedIssue,
      updateStatus,
      IssueStatus,
      issuesToDo,
      issuesInProgress,
      issuesFinished,
    };
  },
});
