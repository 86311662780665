import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "c-issue-details-header" }
const _hoisted_2 = {
  key: 0,
  class: "c-issue-details-header__id"
}
const _hoisted_3 = { class: "c-issue-details-header__id__number" }
const _hoisted_4 = { class: "c-issue-details-header__options" }
const _hoisted_5 = { class: "c-issue-details-header__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_back = _resolveComponent("icon-back")
  const _component_icon_pdf = _resolveComponent("icon-pdf")
  const _component_icon_visibility = _resolveComponent("icon-visibility")
  const _component_c_options_dropdown = _resolveComponent("c-options-dropdown")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      (!_ctx.isMobile)
        ? (_openBlock(), _createBlock("div", _hoisted_2, [
            _createVNode("span", _hoisted_3, _toDisplayString(_ctx.issueNumber), 1),
            _createVNode("span", {
              class: "c-issue-details-header__id__tag",
              style: {backgroundColor: _ctx.priority.color}
            }, _toDisplayString(_ctx.priority.name), 5)
          ]))
        : (_openBlock(), _createBlock("div", {
            key: 1,
            class: "c-issue-details-header__back",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close-panel')))
          }, [
            _createVNode(_component_icon_back, { class: "c-issue-details-header__back__icon" }),
            _createVNode("span", null, _toDisplayString(_ctx.useTranslations('lists')), 1)
          ])),
      _createVNode("div", _hoisted_4, [
        _createVNode("div", {
          title: _ctx.useTranslations('tooltipsLabel.iconPdf')
        }, [
          _createVNode(_component_icon_pdf, {
            class: "c-issue-details-header__options__icon",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('get-pdf')))
          })
        ], 8, ["title"]),
        _createVNode("div", { title: _ctx.tooltipsTitle }, [
          _createVNode(_component_icon_visibility, {
            class: ["c-issue-details-header__options__icon", {'c-issue-details-header__options__icon--active' : _ctx.isObserved}],
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('toggle-observed')))
          }, null, 8, ["class"])
        ], 8, ["title"]),
        _createVNode(_component_c_options_dropdown, {
          "closed-icon-class": 'hover:text-primary-600',
          icon: "iconMore",
          "icon-class": 'fill-current text-gray-800 w-6 h-6 sm:w-10 sm:h-10',
          items: _ctx.issueDropdown,
          "opened-icon-class": 'fill-current text-primary-600',
          "separate-last-item": _ctx.isCreator
        }, null, 8, ["items", "separate-last-item"])
      ])
    ]),
    _createVNode("span", _hoisted_5, _toDisplayString(_ctx.title), 1)
  ], 64))
}