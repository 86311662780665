
import { defineComponent, PropType, Ref, ref } from 'vue';
import { FileType }              from '@/views/issues/issues.types';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';

export default defineComponent({
  name: 'CImageWrapper',
  props: {
    media: {
      type: Array as PropType<any[]>,
      default: () => []
    }
  },
  setup(props) {
    const { getFile } = useIssues(UseIssuesContext.ISSUES)
    const imageUuids: any[] = props.media.map((file) => {
      if (file.type === FileType.IMAGE) {
        return file.fileUuid;
      }
    });
    const urls: Ref<string[]> = ref([])
    const getFileUrls = async ():Promise<void> => {
      const files = imageUuids.map((uuid: string) => getFile(uuid));
      return Promise.all(files).then((data:any) => {
        data.forEach((file:any) => {
          urls.value.push(file.config.baseURL + file.config.url)
        });
      })
    }
    getFileUrls();
    return { urls }
  }
})
