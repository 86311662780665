import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-f5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_close = _resolveComponent("icon-close")

  return (_ctx.index < _ctx.maximumTags)
    ? (_openBlock(), _createBlock("div", {
        key: 0,
        class: "flex bg-light-green text-white text-bold m-f3 items-center text-12 p-f3 px-f5 rounded-full cursor-pointer",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('delete-item')), ["stop"]))
      }, [
        _createVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
        _createVNode(_component_icon_close, { class: "rounded-full w-4 h-4 flex items-center justify-center text-white cursor-pointer" })
      ]))
    : _createCommentVNode("", true)
}