import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "c-issues-list box-border z-0 mt-f21 overflow-y-auto scroll-style" }
const _hoisted_2 = { class: "w-full flex justify-between pb-f21 bg-white z-10 shadow-around sticky top-0" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-between box-border"
}
const _hoisted_4 = {
  key: 1,
  class: "pf-8 text-center text-blue-navy mt-f13"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issues_list_title = _resolveComponent("c-issues-list-title")
  const _component_c_issues_list_item = _resolveComponent("c-issues-list-item")
  const _component_c_drag_and_drop = _resolveComponent("c-drag-and-drop")
  const _component_c_dropzone = _resolveComponent("c-dropzone")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_c_issues_list_title, {
        class: "md:w-1/3",
        "issue-status": _ctx.IssueStatus.TODO
      }, null, 8, ["issue-status"]),
      _createVNode(_component_c_issues_list_title, {
        class: "md:w-1/3 md:mx-f13",
        "issue-status": _ctx.IssueStatus.IN_PROGRESS
      }, null, 8, ["issue-status"]),
      _createVNode(_component_c_issues_list_title, {
        class: "md:w-1/3",
        "issue-status": _ctx.IssueStatus.DONE
      }, null, 8, ["issue-status"])
    ]),
    (_ctx.issues.length)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_c_dropzone, {
            class: "md:w-1/3 min-h-540",
            value: _ctx.IssueStatus.ASSIGNED,
            onOnDropEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateStatus($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.issuesToDo, (issue, index) => {
                return (_openBlock(), _createBlock(_component_c_drag_and_drop, {
                  key: issue.uuid,
                  index: index,
                  item: issue.uuid,
                  onClick: ($event: any) => (_ctx.setSelectedIssue(issue.uuid))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_c_issues_list_item, { issue: issue }, null, 8, ["issue"])
                  ]),
                  _: 2
                }, 1032, ["index", "item", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_c_dropzone, {
            class: "md:w-1/3 md:mx-f13 min-h-540",
            value: _ctx.IssueStatus.IN_PROGRESS,
            onOnDropEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateStatus($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.issuesInProgress, (issue, index) => {
                return (_openBlock(), _createBlock(_component_c_drag_and_drop, {
                  key: issue.uuid,
                  index: index,
                  item: issue.uuid,
                  onClick: ($event: any) => (_ctx.setSelectedIssue(issue.uuid))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_c_issues_list_item, { issue: issue }, null, 8, ["issue"])
                  ]),
                  _: 2
                }, 1032, ["index", "item", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_c_dropzone, {
            class: "md:w-1/3 min-h-540",
            value: _ctx.IssueStatus.DONE,
            onOnDropEvent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateStatus($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.issuesFinished, (issue, index) => {
                return (_openBlock(), _createBlock(_component_c_drag_and_drop, {
                  key: issue.uuid,
                  index: index,
                  item: issue.uuid,
                  onClick: ($event: any) => (_ctx.setSelectedIssue(issue.uuid))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_c_issues_list_item, { issue: issue }, null, 8, ["issue"])
                  ]),
                  _: 2
                }, 1032, ["index", "item", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ]))
      : (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.useGlobalTranslations('noData')), 1))
  ]))
}