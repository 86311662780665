
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, reactive, ref, Ref, toRefs, watch } from 'vue';
import { useVuelidate, ValidationArgs }                                                                      from '@vuelidate/core';
import { maxLength, requiredIf }                                                                             from '@vuelidate/validators';
import { useTranslations }                                                                                   from '@/hooks/use-translations';
import { switchTypes }                                                                                       from '@/types';
import { FormValues, IssueNote, NoteType }                                                                   from '@/views/issues/issues.types';
import CIssueNote
  from '@/views/issues/components/issues-details/components/issue-details-notes/components/issue-note/issue-note.component.vue';
import CIssueInput
  from '@/views/issues/components/issues-details/components/issue-details-notes/components/issue-input/issue-input.component.vue';
import CCustomSwitch                                                                                         from '@/components/common/custom-switch/custom-switch.component.vue';
import { useGlobals }                                                                                        from '@/hooks/use-globals';
import CIssueNoteImagePreview
  from '@/views/issues/components/issues-details/components/issue-details-notes/components/issue-note-image-preview.component.vue';
import { useIssues, UseIssuesContext }                                                                       from '@/hooks/use-issues';
import { formatFilesData }                                                                                   from '@/store/modules/issues/issues.helpers';

export default defineComponent({
  name: 'CIssueDetailsNotes',
  components: { CIssueNoteImagePreview, CCustomSwitch, CIssueInput, CIssueNote },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { store } = useGlobals();
    const { addNoteAndGetAllNotes } = useIssues(UseIssuesContext.ISSUES);
    const isHistoryShown: Ref<boolean> = ref(false);
    const issueNotes = ref<HTMLElement | null>(null);
    const fixedHeight = ref<string>('');
    const showImagePreview: Ref<boolean> = ref(false);
    const noteValues: FormValues = reactive({
      files: [],
      description: '',
    });

    const notes: ComputedRef<IssueNote[]> = computed(() => store.state.issues.selectedIssueNotes);

    const filteredNotes: ComputedRef<IssueNote[]> = computed(() => {
      return !isHistoryShown.value
        ? notes.value.filter(note => note.noteType !== NoteType.SYSTEM)
        : notes.value;
    });
    const rules = computed(() => ({
      description: {
        required: requiredIf(!noteValues.files.length),
        maxLength: maxLength(150),
      },
    }));

    const validator = useVuelidate((rules as unknown) as ValidationArgs, noteValues as any);

    const removeFromPreview = (index: number): void => {
      noteValues.files = Array.from(noteValues.files)
        .filter((_, idx) => idx !== index);
    };

    const addNewNote = async (): Promise<void> => {
      validator.value.$touch();
      if (!validator.value.$error) {
        await addNoteAndGetAllNotes({ files: noteValues.files , description: noteValues.description, issueUuid: props.uuid });
        showImagePreview.value = false;
        resetInput();
      }
    };

    const resetInput = (): void => {
      validator.value.$reset();
      noteValues.description = '';
      noteValues.files = [];
    };

    const scrollDown = (): void => {
      if (issueNotes.value) {
        issueNotes.value.scrollTo(0, issueNotes.value.scrollHeight);
      }
    };

    watch(noteValues, (newValue) => {
      if (newValue.files.length) {
        showImagePreview.value = true;
      }
    });
    const getFixedHeight = (): void => {
      const noteWrapper = issueNotes.value as HTMLElement;
      const marginBottom = 20;
      const height = document.body.offsetHeight - 2 * noteWrapper.offsetTop - marginBottom;
      fixedHeight.value = `${height}px`;
    };
    onMounted(() => {
      window.addEventListener('resize', getFixedHeight);
      getFixedHeight();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', getFixedHeight);
    });

    return {
      useTranslations,
      filteredNotes,
      ...toRefs(noteValues),
      isHistoryShown,
      switchTypes,
      validator,
      showImagePreview,
      issueNotes,
      fixedHeight,
      addNewNote,
      scrollDown,
      removeFromPreview,
    };
  },
});
