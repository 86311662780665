
import { defineComponent } from 'vue';
import { useDragAndDrop }  from '@/components/common/drag-and-drop/use-drag-and-drop.hook';

export default defineComponent({
  name: 'CDragAndDrop',
  props: {
    isDraggable: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: [Object,String],
      required: true,
    },
  },
  setup(props, context) {
    const {
      onDragStart,
      onDrop,
      onDragLeave,
      onDragOver,
    } = useDragAndDrop(context);

    return {
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
    };
  },
});
