
import { computed, defineComponent, PropType, toRef } from 'vue';
import CFormMultiSelectTag
  from '@/components/common/form/form-multi-select/components/form-multi-select-tag/form-multi-select-tag.component.vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import IconCheck from '@/components/icons/icon-check.component.vue';
import { SelectItem } from '@/types';
import { useSelect } from '@/hooks/use-select';

export default defineComponent({
  name: 'CFormMultiSelect',
  components: { IconCheck, CFormMultiSelectTag },
  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      required: true,
    },
    maximumTags: {
      type: Number,
      required: false,
      default: 3,
    },
    label: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'select'],
  setup(props, { emit }) {

    const {
      filteredItems,
      input,
      isFocused,
      setIsFocus,
      container,
      handleFocusOut,
      getListItemRefs,
      handleKeyDownEvent,
      liElements,
      selectValue,
      searchValue
    } = useSelect(toRef(props, 'items'), (item) => {
      const set = new Set(props.modelValue);

      set.has(item.value)
        ? set.delete(item.value)
        : set.add(item.value);

      emit('update:modelValue', [...set])
      emit('select', [...set])
    }, true)

    const selectedItems = computed(() => {
      return props.items.filter(item => {
        return props.modelValue.includes(item.value)
      })

    })

    const isSelected = (item: SelectItem): boolean => {
      return props.modelValue.includes(item.value)
    };

    const deleteItem = (eventItem: SelectItem): void => {
      const items = props.modelValue.filter(item => item !== eventItem.value)
      emit('update:modelValue', items);
    };

    return {
      setIsFocus,
      deleteItem,
      isFocused,
      input,
      filteredItems,
      useGlobalTranslations,
      isSelected,
      selectValue,
      searchValue,
      container,
      handleKeyDownEvent,
      liElements,
      getListItemRefs,
      handleFocusOut,
      selectedItems
    };
  },
});
