
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useGlobals }                                     from '@/hooks/use-globals';
import { IssueStatus, ListIssue }              from '@/views/issues/issues.types';
import { useTranslations }                     from '@/hooks/use-translations';
import CIssuesListItem                       from '@/views/issues/components/issues-list-item/issues-list-item.component.vue';
import { getViewStorePath }                    from '@/store/store.helpers';
import { name as vIssues }             from '@/views/issues/issues.route';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import CPagination from '@/components/common/pagination/pagination.component.vue';
import { ISSUES_PAGINATION } from '@/views/issues/helpers/variables';

export default defineComponent({
  name: 'CIssuesListDesktop',
  components: { CIssuesListItem, CPagination },
  setup() {
    const { store } = useGlobals();
    const { getSelectedIssue } = useIssues(UseIssuesContext.ISSUES)
    const selectedIssueStatus: Ref<IssueStatus> = ref(IssueStatus.TODO);
    const issuesToDo: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesToDo']);
    const issuesInProgress: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesInProgress']);
    const issuesFinished: ComputedRef<IssueStatus> = computed(() => store.getters['issues/issuesFinished']);

    const changeSelectedStatus = (status: IssueStatus) => {
      selectedIssueStatus.value = status;
    };

    const setSelectedIssue = async (issueUuid: string) => {
      await getSelectedIssue(issueUuid)
      store.commit(`${getViewStorePath(vIssues)}/openSidePanel`);
    };

    const getUsers = () => {
      store.dispatch('issues/getIssues')
    }

    return {
      getUsers,
      setSelectedIssue,
      changeSelectedStatus,
      useTranslations,
      selectedIssueStatus,
      IssueStatus,
      issuesToDo,
      issuesInProgress,
      issuesFinished,
      ISSUES_PAGINATION
    };
  },
});
