
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { useVuelidate }                                 from '@vuelidate/core';
import { required }                            from '@vuelidate/validators';
import CButton                                 from '@/components/common/button/button.component.vue';
import CCloseButton                            from '@/components/common/close-button/close-button.component.vue';
import { useGlobalTranslations }                 from '@/hooks/use-translations';

export default defineComponent({
  name: 'CAddIssueNoteForm',
  components: { CCloseButton, CButton },
  props: {
    issueUuid: {
      type: String,
      required: true,
    },
  },
  emits: [ 'close-form', 'add-new-note' ],
  setup(props, { emit }) {
    const noteValue: Ref<string> = ref('');
    const noteHTMLElement: Ref<null | HTMLElement> = ref(null)

    const rules = computed(() => ({ noteValue: { required } }));

    const validator = useVuelidate(rules, { noteValue });

    const addNewNote = (): void => {
      validator.value.$touch();
      if (!validator.value.$error) {
        emit('add-new-note', { issueUuid: props.issueUuid, description: noteValue.value, files: [] });
        validator.value.$reset();
        closeForm();
      }
    };

    onMounted(() => {
      noteHTMLElement.value && noteHTMLElement.value.focus()
    })

    const closeForm = (): void => {
      emit('close-form');
    };

    return {
      noteValue,
      useGlobalTranslations,
      addNewNote,
      closeForm,
      noteHTMLElement
    };
  },
});
