
import { defineComponent, PropType } from 'vue';
import { IconButtonClass, iconTypes }  from '@/types';
import IconVisibility        from '@/components/icons/icon-visibility.component.vue'
import IconTrashCan from '@/components/icons/icon-trash-can.component.vue';
import IconClose from '@/components/icons/icon-close.component.vue'
import IconWarning from '@/components/icons/icon-warning.component.vue'
import IconDiscard       from '@/components/icons/icon-discard.component.vue'
import IconSave from '@/components/icons/icon-save.component.vue';

export default defineComponent({
  name: 'CButtonWithIcon',
  components: {
    IconSave,
    IconVisibility,
    IconTrashCan,
    IconClose,
    IconWarning,
    IconDiscard
  },
  props: {
    icon: {
      type: String as PropType<iconTypes>,
      required: true
    },
    classList: {
      type: String as PropType<IconButtonClass>,
      default: IconButtonClass.GREEN
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonActiveTitle: {
      type: String,
      default: ''
    },
    buttonDisabledTitle: {
      type: String,
      default: ''
    }
  },
  emits: ['click']
})

