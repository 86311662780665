
import { computed, ComputedRef, defineComponent, PropType, reactive } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';
import CFormInput from '@/components/common/form/form-input/form-input.component.vue';
import CFormTextarea from '@/components/common/form/form-textarea/form-textarea.component.vue';
import CLinkedIssuesSelected
  from '@/views/issues/components/issues-edit/linked-issues/components/linked-issues-selected.component.vue';
import { IssueRelatedIssues, IssueStatus, IssueMainType } from '@/views/issues/issues.types';

export default defineComponent({
  name: 'CIssuesEditStepTwo',
  components: { CLinkedIssuesSelected, CFormTextarea, CFormInput, CFormSelectNew },
  props: {
    form: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    relatedIssues: {
      type: Array as PropType<IssueRelatedIssues[]>,
      default: () => [],
    },
  },
  emits: ['add-task', 'update-related'],
  setup(props) {
    const { store } = useGlobals();
    const formData = reactive(props.form);
    const issueOptions = computed(() => store.getters['issues/editIssueForm'])


    const getExpectationsAndSolutionsItems = () => {
      store.dispatch('issues/getExpectationsTypes')
      store.dispatch('issues/getSolutionsTypes')
    }

    const isIssueCare = computed(() => formData.mainType === IssueMainType.CARE)

    getExpectationsAndSolutionsItems()


    return {
      formData,
      issueOptions,
      useTranslations,
      IssueStatus,
      IssueMainType,
      isIssueCare
    };
  },
});
