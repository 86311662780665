
import { defineComponent } from 'vue';
import { useDragAndDrop }  from '@/components/common/drag-and-drop/use-drag-and-drop.hook';

export default defineComponent({
  name: 'CDropzone',
  props: {
    value: {
      type: [ String, Object ],
      required: true,
    },
  },
  setup(props, context) {
    const { onDrop, onDragOver, onDragLeave } = useDragAndDrop(context);

    return {
      onDrop,
      onDragOver,
      onDragLeave,
    };
  },
});
