import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-issues-table scroll-style" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_table = _resolveComponent("c-table")
  const _component_c_pagination = _resolveComponent("c-pagination")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_c_table, {
      columns: _ctx.columns,
      rows: _ctx.issues,
      "selected-item-uuid": _ctx.selectedIssue?.uuid,
      onClick: _ctx.showSidePanel,
      onDataEvent: _ctx.showSidePanel,
      onSort: _ctx.handleSort
    }, null, 8, ["columns", "rows", "selected-item-uuid", "onClick", "onDataEvent", "onSort"]),
    _createVNode(_component_c_pagination, {
      name: _ctx.ISSUES_PAGINATION,
      onUpdatePagination: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getUsers()))
    }, null, 8, ["name"])
  ]))
}