import { State, ViewModes } from '@/views/issues/issues.types';
import { columnTypes } from '@/components/common/table/table.types';
import { FilterTypes } from '@/types';

export const state: State = {
  viewMode: ViewModes.TABLE,
  isSidePanelVisible: false,
  isEditMode: false,
  tableColumns: [
    {
      name: 'issueNumber',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'name',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'issueStatus',
      type: columnTypes.TAGS,
      filter: FilterTypes.SORT,
    },
    {
      name: 'priority',
      type: columnTypes.TAGS,
      filter: FilterTypes.SORT,
    },
    {
      name: 'location',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'deadline',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'hotelUser',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'group',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
    {
      name: 'creationDate',
      type: columnTypes.TEXT,
      filter: FilterTypes.SORT,
    },
  ],
};
