import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-12"
}
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "text-gray-800" }
const _hoisted_4 = { class: "bg-gray-150 flex flex-col text-blue-navy rounded-xl" }
const _hoisted_5 = { class: "p-f13" }
const _hoisted_6 = {
  key: 1,
  class: "text-10"
}
const _hoisted_7 = { class: "border p-f21 border-gray-800 text-blue-navy rounded-xl w-full flex flex-col" }
const _hoisted_8 = { class: "text-gray-800 " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_image_wrapper = _resolveComponent("c-image-wrapper")

  return (_ctx.note.noteType === _ctx.NoteType.USER&&(_ctx.note.description.length||_ctx.note.media.length))
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", {
          class: ["w-full flex", _ctx.lastName]
        }, [
          _createVNode("span", _hoisted_2, _toDisplayString(`${_ctx.note.user.name}  ${_ctx.note.user.lastName}`), 1),
          _createVNode("span", _hoisted_3, _toDisplayString(_ctx.formattedDate), 1)
        ], 2),
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, _toDisplayString(_ctx.note.description), 1),
          _createVNode(_component_c_image_wrapper, {
            key: _ctx.note.media,
            class: "p-f21",
            media: _ctx.note.media
          }, null, 8, ["media"])
        ])
      ]))
    : (_ctx.note.noteType === _ctx.NoteType.SYSTEM)
      ? (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode("span", _hoisted_8, _toDisplayString(_ctx.formattedDate), 1),
            _createVNode("div", null, _toDisplayString(_ctx.note.description), 1)
          ])
        ]))
      : _createCommentVNode("", true)
}