
import { defineComponent, PropType, toRef, watch } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { SelectItem } from '@/types';
import CFormErrorIcon from '@/components/common/form/form-error-icon/form-error-icon.component.vue';
import { useSelect } from '@/hooks/use-select';


export default defineComponent({
  name: 'CCombobox',
  components: { CFormErrorIcon },
  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array as PropType<SelectItem[]>,
      default: () => [],
    },
    inputId: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      default: '',
      type: String,
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['update:items', 'update:selected'],
  setup(props, { emit }) {
    const defaultPlaceholder = `${useGlobalTranslations('search')}...`;
    const {
      container,
      input,
      liElements,
      isFocused,
      searchValue,
      filteredItems,
      handleKeyDownEvent,
      setIsFocus,
      selectValue,
      getListItemRefs,
      handleFocusOut
    } = useSelect(toRef(props, 'items'), (item: SelectItem) => {
      emit('update:selected', item.value);
    })

    watch(() => props.items, () => {
      searchValue.value = ''
    })


    return {
      handleKeyDownEvent,
      isFocused,
      defaultPlaceholder,
      setIsFocus,
      selectValue,
      searchValue,
      filteredItems,
      liElements,
      getListItemRefs,
      container,
      handleFocusOut,
      input
    };
  },
});
