
import { defineComponent, PropType } from 'vue';
import IconClose from '@/components/icons/icon-close.component.vue';

export default defineComponent({
  name: 'CIssueNoteImagePreview',
  components: { IconClose },
  props: {
    files: {
      type: Array as PropType<any[]>,
      default: () => []
    }
  },
  emits: ['scrollDown', 'removeImage'],
  setup(props, context) {
    const fileUrls = Array.from(props.files).map(file => URL.createObjectURL(file));
    setTimeout(() => {
      context.emit('scrollDown');
    }, 100)
    return {
      fileUrls
    }
  }
})

