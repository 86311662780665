
import { computed, ComputedRef, defineComponent } from 'vue';
import { useGlobals }                             from '@/hooks/use-globals';
import CTable                                     from '@/components/common/table/table.component.vue';
import { getViewStorePath }                       from '@/store/store.helpers';
import { Issue }                                  from '@/views/issues/issues.types';
import { name as vIssues }                        from '@/views/issues/issues.route';
import { sortPayload }                            from '@/types';
import { useIssues, UseIssuesContext }            from '@/hooks/use-issues';
import { ASC }                                    from '@/helpers/variables';
import CPagination from '@/components/common/pagination/pagination.component.vue';
import { ISSUES_PAGINATION } from '@/views/issues/helpers/variables';

export default defineComponent({
  name: 'CIssuesTable',
  components: { CTable, CPagination },
  setup() {
    const { store } = useGlobals();
    const { getAllIssues, getSelectedIssue } = useIssues(UseIssuesContext.ISSUES);
    const columns = store.state.views.vIssues.tableColumns;
    const issues = computed(() => store.getters[ 'issues/issuesTableData' ]);
    const selectedIssue: ComputedRef<Issue> = computed(() => store.state.issues.selectedIssue);

    const handleSort = (sort: sortPayload) => {
      store.commit('setIssueParamsSort', sort);
      getAllIssues();
    };

    const showSidePanel = async (issueUuid: string) => {
      await getSelectedIssue(issueUuid);
      store.commit(`${getViewStorePath(vIssues)}/openSidePanel`);
    };

    handleSort({ field: 'issueNumber', direction: ASC });

    const getUsers = () => {
      store.dispatch('issues/getIssues')
    }
    return {
      issues,
      columns,
      getUsers,
      handleSort,
      showSidePanel,
      selectedIssue,
      ISSUES_PAGINATION,
    };
  },
});
