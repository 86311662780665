
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-modal']
});
