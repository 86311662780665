import { ref, Ref } from 'vue';

interface UseSidePanel {
    openSidePanel: () => void;
    closeSidePanel: () => void;
    isSidePanelVisible: Ref<boolean>
}

export const useSidePanel = (): UseSidePanel => {

  const isSidePanelVisible:Ref<boolean> = ref(false);

  const openSidePanel = ():void => {
    isSidePanelVisible.value = true
  }

  const closeSidePanel = ():void => {
    isSidePanelVisible.value = false
  }

  return {
    openSidePanel,
    closeSidePanel,
    isSidePanelVisible
  }

}