
import { computed, ComputedRef, defineComponent, reactive, Ref, ref, watch } from 'vue';
import { iconTypes, switchTypes }                                            from '@/types';
import { useGlobals }                                                        from '@/hooks/use-globals';
import { useTranslations }                                                   from '@/hooks/use-translations';
import { getViewStorePath }                                                  from '@/store/store.helpers';
import { name as vIssues }                                                   from '@/views/issues/issues.route';
import { FiltersConfiguration }                                              from '@/views/issues/issues.types';
import IconFilter                                                            from '@/components/icons/icon-filter.component.vue';
import CirceIconWrapper                                                      from '@/components/icons/wrapper/circle-icon-wrapper.component.vue';
import CSidePanelWithBackground                                              from '@/components/common/side-panel-with-background/side-panel-with-background.component.vue';
import CCustomSwitch                                                         from '@/components/common/custom-switch/custom-switch.component.vue';
import CSearchbar                                                            from '@/components/common/searchbar/searchbar.component.vue';
import CIssuesFiltersPanel                                                   from '@/views/issues/components/issues-filters-panel/issues-filters-panel.component.vue';
import { IssueFilterType, IssueMainType }                                    from '@/store/store.types';
import { useDeviceSize }                                                     from '@/hooks/use-page-size';
import { getFiltersIcon }                                                    from '@/views/issues/helpers/helpers';
import { useIssues, UseIssuesContext }                                       from '@/hooks/use-issues';
import IconDocumentExcel                                                     from '@/components/icons/icon-document-excel.component.vue'

export default defineComponent({
  name: 'CIssuesHeaderPanel',
  components: {
    IconDocumentExcel,
    IconFilter,
    CSearchbar,
    CCustomSwitch,
    CIssuesFiltersPanel,
    CSidePanelWithBackground,
    CirceIconWrapper,
  },
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { getAllIssues } = useIssues(UseIssuesContext.ISSUES)
    const filtersConfiguration: FiltersConfiguration = reactive(store.state.issues.filtersConfiguration);
    const activeIcon: Ref<string> = ref(getFiltersIcon(store.state.issuesParams.filter));
    const isFiltersPanelOpen: Ref<boolean> = ref(false);
    const isNearDeadline: Ref<boolean> = ref(store.state.issuesParams.time);
    const searchQuery: ComputedRef<string> = computed(() => store.state.issuesParams.searchQuery);
    const issuesParamsType: ComputedRef<IssueMainType> = computed(() => store.state.issuesParams.type);
    const viewMode: Ref<boolean> = ref(false);
    const issuesParamFilter: ComputedRef<IssueFilterType> = computed(() => store.state.issuesParams.filter)

    const checkExcelDocument = () => {
      store.dispatch('issues/generateExcel');
    }

    const setInitialActiveIcon = (param: IssueFilterType): void => {
      switch (param) {
        case IssueFilterType.ALL:
          activeIcon.value = iconTypes.SQUARES;
          break;
        case IssueFilterType.GROUP:
          activeIcon.value = iconTypes.PEOPLE;
          break;
        case IssueFilterType.ASSIGNED:
          activeIcon.value = iconTypes.PERSON;
          break;
      }
    }

    setInitialActiveIcon(issuesParamFilter.value)

    const updateIssuesFilterParam = (payload: string): void => {
      let filter = IssueFilterType.ASSIGNED
      switch (payload) {
        case iconTypes.PEOPLE:
          filter = IssueFilterType.GROUP;
          break;
        case iconTypes.SQUARES:
          filter = IssueFilterType.ALL;
          break;
        default:
          break;
      }
      store.commit('setIssueParamsFilter', filter);
      getAllIssues();
    }

    const updateIssuesSearchQueryParam = (payload: string): void => {
      store.commit('setIssueParamsSearchQuery', payload);
      getAllIssues();
    }

    watch(isNearDeadline, () => {
      store.commit('setIssueParamsTime', isNearDeadline.value);
      getAllIssues()
    })
    watch(viewMode, () => {
      store.commit(`${getViewStorePath(vIssues)}/setViewMode`, viewMode.value);
    })

    const icons = [
      {
        name: iconTypes.PERSON,
        label: 'assigned',
        color: 'redLight',
      }, {
        name: iconTypes.PEOPLE,
        label: 'group',
        color: 'violetLight',
      }, {
        name: iconTypes.SQUARES,
        label: 'all',
        color: 'yellow',
      }];

    return {
      updateIssuesFilterParam,
      updateIssuesSearchQueryParam,
      useTranslations,
      issuesParamFilter,
      filtersConfiguration,
      isFiltersPanelOpen,
      icons,
      searchQuery,
      activeIcon,
      viewMode,
      issuesParamsType,
      isNearDeadline,
      isMobile,
      switchTypes,
      IssueMainType,
      checkExcelDocument
    };
  },
});
