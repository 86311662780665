
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import { format, parseISO }                                 from 'date-fns';
import { IssueNote, NoteType }                              from '@/views/issues/issues.types';
import CImageWrapper                                        from '@/views/issues/components/issues-details/components/issue-details-notes/components/image-wrapper.component.vue';
import { usePageSize }                                      from '@/hooks/use-page-size';

export default defineComponent({
  name: 'CIssueNote',
  components: { CImageWrapper },
  props: {
    note: {
      type: Object as PropType<IssueNote>,
      required: true
    }
  },
  setup(props) {
    const formattedDate = format(parseISO(props.note.creationDate), 'yyyy.MM.dd kk:mm');

    const { pageWidth } = usePageSize();

    const lastName: ComputedRef<string> = computed(() => {
      if (pageWidth.value < 370) {
        return 'flex-col items-end'
      } else {
        return 'justify-between'
      }
    });


    return {
      formattedDate,
      pageWidth,
      lastName,
      NoteType,
    }
  }
})
