
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useGlobals }                                       from '@/hooks/use-globals';
import { Issue, IssueStatus, ListIssue }                    from '@/views/issues/issues.types';
import CIssueListItemWithConfirmation                       from '@/views/controller/components/issue-list-item-with-confirmation.component.vue';
import { useIssues, UseIssuesContext }                      from '@/hooks/use-issues';
import CControllerIssuesDetails                             from '@/views/controller/components/controller-issues-details.component.vue';
import { useTranslations }                                    from '@/hooks/use-translations';

enum ControllerMobileState {
  LIST_MODE = 'LIST_MODE',
  DETAILS_MODE = 'DETAILS_MODE'
}

export default defineComponent({
  name: 'CControllerIssuesList',
  components: { CControllerIssuesDetails, CIssueListItemWithConfirmation },
  setup() {
    const { store } = useGlobals()
    const { changeStatusAndGetAllIssues, getSelectedIssue } = useIssues(UseIssuesContext.CONTROLLER)
    const viewMode: Ref<ControllerMobileState> = ref(ControllerMobileState.LIST_MODE);
    const selectedIssue: ComputedRef<Issue> = computed(() => store.state.issues.selectedIssue);
    const issuesList: ComputedRef<ListIssue[]> = computed(() => store.state.issues.issues);

    const setViewMode = (mode: ControllerMobileState): void => {
      viewMode.value = mode
    }

    const handleAcceptIssue = async (): Promise<void> => {
      await changeStatusAndGetAllIssues({ issueStatus: IssueStatus.ENDED, uuid: selectedIssue.value.uuid })
      setViewMode(ControllerMobileState.LIST_MODE)
    }

    const handleSelectIssue = async (id: string) : Promise<void> => {
      await getSelectedIssue(id)
      setViewMode(ControllerMobileState.DETAILS_MODE)
    }


    return {
      issuesList,
      setViewMode,
      handleSelectIssue,
      handleAcceptIssue,
      viewMode,
      ControllerMobileState,
      useTranslations
    }
  }
})
