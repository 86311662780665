import { MutationTree } from 'vuex';
import {

  State,
  ViewModes,
} from '@/views/issues/issues.types';
import { User } from '@/views/administration/views/administration-users/administration-users.types';

export const mutations: MutationTree<State> = {


  openSidePanel(state): void {
    state.isSidePanelVisible = true;
  },
  closeSidePanel(state): void {
    state.isEditMode = false;
    state.isSidePanelVisible = false;
  },
  setViewMode(state, payload: boolean): void {
    state.viewMode = payload ? ViewModes.LIST : ViewModes.TABLE;
  },
  setEditMode(state, isEditMode: boolean): void {
    state.isEditMode = isEditMode;
  },
};
