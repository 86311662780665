
import { computed, ComputedRef, defineComponent, Ref, ref, watch } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { useModal } from '@/components/common/modal/use-modal';
import { useTranslations } from '@/hooks/use-translations';
import { ConfirmationStatement, IconButtonClass, iconTypes, MainType, SelectItem } from '@/types';
import CModal from '@/components/common/modal/modal.component.vue';
import CFiltersConfirmationModal
  from '@/views/issues/components/issues-filters-panel/components/filters-confirmation-modal.component.vue';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import CButtonWithIcon from '@/components/common/button/button-with-icon.component.vue';
import CCombobox from '@/components/common/searchbar/combobox.component.vue';

export default defineComponent({
  name: 'CIssuesFiltersController',
  components: { CCombobox, CButtonWithIcon, CFiltersConfirmationModal, CModal },
  setup() {
    const { store } = useGlobals()
    const {
      getFiltersConfiguration,
      getAllIssues,
      getAllFiltersConfigurations,
      createFiltersConfiguration,
      deleteFiltersConfiguration
    } = useIssues(UseIssuesContext.ISSUES)
    const { isModalVisible, openModal, closeModal } = useModal();
    const amountOfSelectedFilters: ComputedRef<number> = computed(() => store.getters['issues/amountOfSelectedFilters']);
    const filterType: ComputedRef<MainType> = computed(() => store.state.issuesParams.type);
    const allFilters: ComputedRef<SelectItem[]> = computed(() => store.getters['issues/filterConfigLabels']);
    const selectedUuid: Ref<string> = ref('');
    const confirmationState: Ref<ConfirmationStatement> = ref(ConfirmationStatement.DELETE_ITEM_CONFIRMATION);

    const handleSaveConfiguration = async (payload: string): Promise<void> => {
      await createFiltersConfiguration(payload)
      getAllFiltersConfigurationsAndCloseModal()
    }

    const handleDeleteConfiguration = async (): Promise<void> => {
      await deleteFiltersConfiguration(selectedUuid.value)
      getAllFiltersConfigurationsAndCloseModal()
      selectedUuid.value = ''
    }

    const getAllFiltersConfigurationsAndCloseModal = (): void => {
      getAllFiltersConfigurations(filterType.value)
      closeModal()
    }

    const changeConfirmationModalContext = (state: ConfirmationStatement): void => {
      confirmationState.value = state
      openModal()
    }

    const setSelectedFiltersConfiguration = async (): Promise<void> => {
      if (selectedUuid.value) {
        await getFiltersConfiguration({ filterType: filterType.value, configurationUuid: selectedUuid.value })
        getAllIssues()
      }
    }

    getAllFiltersConfigurations(filterType.value)

    watch(selectedUuid, setSelectedFiltersConfiguration)

    return {
      allFilters,
      isModalVisible,
      useTranslations,
      closeModal,
      changeConfirmationModalContext,
      confirmationState,
      ConfirmationStatement,
      setSelectedFiltersConfiguration,
      handleDeleteConfiguration,
      handleSaveConfiguration,
      iconTypes,
      IconButtonClass,
      amountOfSelectedFilters,
      selectedUuid
    }
  }
})
