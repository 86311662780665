import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-issues-filters-panel flex flex-col w-full z-40" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issues_filters_controller = _resolveComponent("c-issues-filters-controller")
  const _component_c_issues_filters_form = _resolveComponent("c-issues-filters-form")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_c_issues_filters_controller),
    _createVNode(_component_c_issues_filters_form, {
      key: _ctx.currentConfigurationUuid,
      "assign-filter": _ctx.issueParams.filter,
      "created-by-filter": _ctx.issueParams.filter,
      "filter-type": _ctx.issueParams.type,
      filters: _ctx.filtersConfiguration,
      "is-near-deadline": _ctx.issueParams.time,
      "view-mode": _ctx.viewMode
    }, null, 8, ["assign-filter", "created-by-filter", "filter-type", "filters", "is-near-deadline", "view-mode"])
  ]))
}