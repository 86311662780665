
import { ComponentPublicInstance, computed, ComputedRef, defineComponent, PropType, Ref, ref } from 'vue';
import { useMouseInElement }                                                                   from '@vueuse/core';
import { IssueStatus, ListIssue }                                                              from '@/views/issues/issues.types';
import { ListItemMovementState }                                                               from '@/views/controller/controller.types';
import CIssuesListItem                                                                       from '@/views/issues/components/issues-list-item/issues-list-item.component.vue';
import CModal                                                                                from '@/components/common/modal/modal.component.vue';
import CAddIssueNoteForm                                                                     from '@/views/controller/components/add-issue-note-form.component.vue';
import { useDeviceSize }                                                                       from '@/hooks/use-page-size';
import IconAccept                                                                            from '@/components/icons/icon-accept.component.vue';
import IconDiscard                     from '@/components/icons/icon-discard.component.vue';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';
import { useModal }                    from '@/components/common/modal/use-modal';


export default defineComponent({
  name: 'CIssueListItemWithConfirmation',
  components: { IconDiscard, IconAccept, CAddIssueNoteForm, CModal, CIssuesListItem },
  props: {
    issue: {
      type: Object as PropType<ListIssue>,
      required: true
    }
  },
  emits: ['setSingleIssue', 'changeIssueStatus'],
  setup(props, { emit }) {
    const listItem: Ref<ComponentPublicInstance<HTMLElement> | null> = ref(null);
    const { elementX, isOutside } = useMouseInElement(listItem);
    const { addNoteAndChangeStatus, changeStatusAndGetAllIssues } = useIssues(UseIssuesContext.CONTROLLER);
    const { openModal, closeModal, isModalVisible } = useModal()
    const componentPosition: Ref<ListItemMovementState> = ref(ListItemMovementState.DEFAULT_POSITION);

    const setActiveListComponentClass: ComputedRef<string> = computed(() => {
      if (componentPosition.value === ListItemMovementState.MOVING_LEFT ||
          componentPosition.value === ListItemMovementState.MOVED_LEFT) {
        return 'active'
      }
      return ''
    })

    let initialCursorPosition = 0 ;

    const initiateMovementAction = (): void => {
      if (!isOutside.value) {
        initialCursorPosition = elementX.value
      }
    }

    const handleSettingSingleIssue = (uuid: string): void => {
      emit('setSingleIssue', uuid)
    }

    const finishMovementAction = (): void => {
      if(componentPosition.value === ListItemMovementState.MOVING_LEFT) {
        componentPosition.value = ListItemMovementState.MOVED_LEFT;
      }
      if(componentPosition.value === ListItemMovementState.MOVING_RIGHT) {
        componentPosition.value = ListItemMovementState.DEFAULT_POSITION
      }

    }

    const moveComponent = (): void => {
      if (initialCursorPosition > elementX.value && componentPosition.value === ListItemMovementState.DEFAULT_POSITION) {
        componentPosition.value = ListItemMovementState.MOVING_LEFT
      }
      if (initialCursorPosition < elementX.value && componentPosition.value === ListItemMovementState.MOVED_LEFT) {
        componentPosition.value = ListItemMovementState.MOVING_RIGHT
      }
      initialCursorPosition = 0
    }

    return {
      listItem,
      setActiveListComponentClass,
      componentPosition,
      ListItemMovementState,
      IssueStatus,
      changeStatusAndGetAllIssues,
      addNoteAndChangeStatus,
      openModal,
      closeModal,
      isModalVisible,
      finishMovementAction,
      moveComponent,
      initiateMovementAction,
      handleSettingSingleIssue,

    }
  }
})
