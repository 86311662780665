import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-f13 flex flex-col gap-5" }
const _hoisted_2 = { class: "flex text-color-primary gap-12 border-1 border-gray-400" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_close_button = _resolveComponent("c-close-button")
  const _component_c_form_input = _resolveComponent("c-form-input")
  const _component_c_button = _resolveComponent("c-button")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_c_close_button, {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close-modal')))
      }),
      _createVNode("span", null, _toDisplayString(_ctx.useTranslations(_ctx.modalContent.title)), 1)
    ]),
    (_ctx.confirmationState === _ctx.ConfirmationStatement.SAVE_ITEM_CONFIRMATION)
      ? (_openBlock(), _createBlock("div", _hoisted_3, [
          _createVNode(_component_c_form_input, {
            modelValue: _ctx.configurationName,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.configurationName = $event)),
            error: _ctx.validator.configurationName.$error ? 'required' : null,
            label: _ctx.useTranslations('filterNameLabel')
          }, null, 8, ["modelValue", "error", "label"])
        ]))
      : _createCommentVNode("", true),
    _createVNode("div", _hoisted_4, [
      _createVNode(_component_c_button, {
        is: "red",
        "button-label": _ctx.useTranslations('button.cancel'),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close-modal')))
      }, null, 8, ["button-label"]),
      _createVNode(_component_c_button, {
        "button-label": _ctx.useTranslations(_ctx.modalContent.buttonLabel),
        onClick: _ctx.handleConfirmationAction
      }, null, 8, ["button-label", "onClick"])
    ])
  ]))
}