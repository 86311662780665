
import { computed, ComputedRef, defineComponent, PropType } from 'vue';
import IconBack                      from '@/components/icons/icon-back.component.vue';
import IconVisibility                from '@/components/icons/icon-visibility.component.vue';
import COptionsDropdown              from '@/components/common/options-dropdown/options-dropdown.component.vue';
import { useDeviceSize }             from '@/hooks/use-page-size';
import { ColouredPriority }          from '@/views/issues/issues.types';
import { useIssueDropdown }          from '@/views/issues/hooks/use-issues-dropdown';
import { useTranslations }           from '@/hooks/use-translations';
import IconPdf                       from '@/components/icons/icon-pdf.component.vue';

export default defineComponent({
  name: 'CIssueDetailsHeader',
  components: { IconPdf, COptionsDropdown, IconVisibility, IconBack },
  props: {
    priority: {
      type: Object as PropType<ColouredPriority>,
      required: true,
    },
    isObserved: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    issueNumber: {
      type: String,
      required: true,
    },
  },
  emits: [ 'close-panel', 'toggle-observed', 'get-pdf' ],
  setup(props) {
    const { isMobile } = useDeviceSize();
    const { issueDropdown, isCreator } = useIssueDropdown();
    const tooltipsTitle: ComputedRef<string> = computed(() => props.isObserved ? useTranslations('tooltipsLabel.iconVisibilityOff') : useTranslations('tooltipsLabel.iconVisibilityOn'))

    return {
      useTranslations,
      tooltipsTitle,
      issueDropdown,
      isCreator,
      isMobile,
    };
  },
});
