
import { computed, defineComponent, reactive } from 'vue';
import CFormInput from '@/components/common/form/form-input/form-input.component.vue';
import CFormRadioGroup from '@/components/common/form/form-radio-group/form-radio-group.component.vue';
import CFormSelectNew from '@/components/common/form/form-select/form-select.component-new.vue';
import CFormDatePicker from '@/components/common/form/form-date-picker/form-date-picker.component.vue';
import { priorityList } from '@/views/issues/helpers/variables';
import { useGlobals } from '@/hooks/use-globals';
import { useTranslations } from '@/hooks/use-translations';

export default defineComponent({
  name: 'CIssuesEditStepOne',
  components: { CFormDatePicker, CFormSelectNew, CFormRadioGroup, CFormInput },
  props: {
    form: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { store } = useGlobals();
    const formData = reactive(props.form);
    const issueOptions = computed(() => store.getters['issues/editIssueForm'])
    const clearPickedDate = () => {
      formData.deadline = '';
    }


    return {
      issueOptions,
      formData,
      priorityList,
      useTranslations,
      clearPickedDate,
    };
  },
});
