import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.urls.length)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.urls, (url, index) => {
          return (_openBlock(), _createBlock("img", {
            key: index,
            alt: url,
            class: "object-center w-full",
            crossorigin: "",
            src: url
          }, null, 8, ["alt", "src"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}