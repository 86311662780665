
import { computed, ComputedRef, defineComponent, Ref, ref, watch } from 'vue';
import { useGlobals }                                       from '@/hooks/use-globals';
import { useDeviceSize }                                    from '@/hooks/use-page-size';
import { useTranslations }                                          from '@/hooks/use-translations';
import { AddNotePayload, Issue, IssueNote, IssueStatus, ModifyIssue } from '@/views/issues/issues.types';
import CIssuesFooterButtons                                         from '@/views/issues/components/issues-details/components/issue-details-footer-buttons/issue-details-footer-buttons.component.vue';
import CIssuesUserList                                      from '@/views/issues/components/issues-details/components/issue-details-user-list/issues-details-user-list.component.vue';
import CMainContent                                         from '@/components/main-content/main-content.component.vue';
import CSidePanelWithBackground                             from '@/components/common/side-panel-with-background/side-panel-with-background.component.vue';
import CSidePanel                                           from '@/components/common/side-panel/side-panel.component.vue';
import CIssueDescription                                    from '@/views/issues/components/issues-details/components/issue-details-description/issue-details-description.component.vue';
import CIssueDetailsNotes                                   from '@/views/issues/components/issues-details/components/issue-details-notes/issue-details-notes.component.vue';
import COptionsDropdown                                     from '@/components/common/options-dropdown/options-dropdown.component.vue';
import { getViewStorePath }                                 from '@/store/store.helpers';
import { name as vIssues }                                  from '@/views/issues/issues.route';
import CIssueDetailsHeader                                  from '@/views/issues/components/issues-details/components/issue-details-header/issue-details-header.component.vue';
import CModal                                               from '@/components/common/modal/modal.component.vue';
import CAddIssueNoteForm                                    from '@/views/controller/components/add-issue-note-form.component.vue';
import { useModal }                                         from '@/components/common/modal/use-modal';
import { useIssues, UseIssuesContext }      from '@/hooks/use-issues';
import CIssueDetailsNavigation                              from '@/views/issues/components/issues-details/components/issue-details-navigation/issue-details-navigation.component.vue';
import { IssueDetailsNavigation }                           from '@/views/issues/components/issues-details/issue-details.types';
import { cloneDeep }                                        from 'lodash-es';
import { modifyIssueToPutPayload }                          from '@/views/issues/helpers/helpers';
import CFormInput                from '@/components/common/form/form-input/form-input.component.vue';

export default defineComponent({
  name: 'VIssueDetails',
  components: {
    CIssueDetailsNavigation,
    CIssueDetailsHeader,
    CAddIssueNoteForm,
    CModal,
    COptionsDropdown,
    CIssueDetailsNotes,
    CIssueDescription,
    CSidePanel,
    CMainContent,
    CSidePanelWithBackground,
    CIssuesUserList,
    CIssuesFooterButtons,
    CFormInput
  },
  setup() {
    const { store } = useGlobals();
    const { isMobile } = useDeviceSize();
    const { addNoteAndChangeStatus, changeFollowIssueStatement, changeStatusAndGetAllIssues, getAllIssues, editIssueAndGetAllIssues, getPDF } = useIssues(UseIssuesContext.ISSUES);
    const { isModalVisible, openModal, closeModal } = useModal();
    const activeNavigation: Ref<IssueDetailsNavigation> = ref(IssueDetailsNavigation.NOTES);
    const isUserListOpen: Ref<boolean> = ref(false);
    const issueDetails = computed(() => store.getters['issues/getIssueDetails']);
    const notes: ComputedRef<IssueNote[]> = computed(() => store.state.issues.selectedIssueNotes);
    const issue: ComputedRef<Issue> = computed(() => store.state.issues.selectedIssue)
    const searchUser = ref('');

    const dynamicComponentProps: ComputedRef<{ issueData: Issue } | {uuid: string}> = computed(() =>
      activeNavigation.value !== IssueDetailsNavigation.NOTES ? { ['issueData'] :issueDetails.value } : { uuid: issueDetails.value.uuid }
    )

    const getNextIssue = (): void => {
      //:todo add action
    };
    const getPreviousIssue = (): void => {
      //:todo add action
    };

    const closePanel = (): void => {
      store.commit(`${getViewStorePath(vIssues)}/closeSidePanel`);
    };

    const issueFollowToggle = async (): Promise<void> => {
      await changeFollowIssueStatement(!issueDetails.value.isObserved, issueDetails.value.uuid)
      getAllIssues()

    };
    const changeNavigation = (navigation: IssueDetailsNavigation): void => {
      activeNavigation.value = navigation;
    };


    const getIssueDetailsComponent = (): string => {
      switch (activeNavigation.value) {
        case IssueDetailsNavigation.NOTES:
          return 'c-issue-details-notes';
        case IssueDetailsNavigation.INFORMATION:
          return 'c-issue-description';
      }
    };

    const toggleUserList = (): void => {
      isUserListOpen.value = !isUserListOpen.value;
    };

    const changeUser = (userUuid: string): void => {
      const modifiedIssueData = modifyIssueToPutPayload(cloneDeep(issue.value), ModifyIssue.USER, userUuid);
      editIssueAndGetAllIssues({ issueUuid: issue.value.uuid, modifyIssueData: modifiedIssueData })
      toggleUserList();
    };

    const changeIssueStatus = async (): Promise<void> => {
      let status: IssueStatus
      switch (issueDetails.value.status) {
        case IssueStatus.ASSIGNED:
        case IssueStatus.NEW:
          status = IssueStatus.IN_PROGRESS;
          break;
        case IssueStatus.ENDED:
        case IssueStatus.IN_PROGRESS:
          status = IssueStatus.DONE;
          break;
        default:
          status = IssueStatus.IN_PROGRESS;
          break
      }

      await changeStatusAndGetAllIssues({ uuid: issueDetails.value.uuid, issueStatus: status })
    };

    const acceptIssue = async (): Promise<void> => {
      await changeStatusAndGetAllIssues( { uuid: issueDetails.value.uuid, issueStatus: IssueStatus.ENDED })
      closePanel();
    };

    const declineIssue = (payload: AddNotePayload): void => {
      addNoteAndChangeStatus(payload);
      getAllIssues()
      closePanel();
    };

    const getExportedPDF = async (): Promise<void> => {
      await getPDF(issueDetails.value.uuid)
    }

    watch(()=> searchUser.value, (newValue)=> {
      store.dispatch('issues/getAllUsers', newValue)
    })

    return {
      activeNavigation,
      isUserListOpen,
      notes,
      isMobile,
      issueDetails,
      isModalVisible,
      openModal,
      closeModal,
      addNoteAndChangeStatus,
      dynamicComponentProps,
      changeNavigation,
      getIssueDetailsComponent,
      closePanel,
      acceptIssue,
      declineIssue,
      changeIssueStatus,
      getNextIssue,
      getPreviousIssue,
      issueFollowToggle,
      changeUser,
      toggleUserList,
      getExportedPDF,
      useTranslations,
      searchUser,
    };
  },
});
