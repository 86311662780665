
import { defineComponent, onUpdated } from 'vue';
import cCloseButton                   from '@/components/common/close-button/close-button.component.vue';

export default defineComponent({
  name: 'CSidePanelInView',
  components: {
    cCloseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: [ 'close-panel', 'save' ],
  setup(props, { emit }) {
    const keydown = (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        emit('close-panel')
      }
    };

    onUpdated(() => {
      if(!props.isVisible) {
        document.removeEventListener('keydown', keydown)
      } else {
        document.addEventListener('keydown', keydown);
      }
    })
  }
});
