import { renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "bg-white rounded-xl min-w-3/4 absolute top-1/4 left-1/2 transform -translate-x-1/2 sm:min-w-1/2 lg:min-w-1/3 2xl:min-w-1/4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    (_ctx.isOpen)
      ? (_openBlock(), _createBlock("div", {
          key: 0,
          class: "h-screen w-screen fixed bg-black bg-opacity-25 z-9999",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('close-modal')), ["self"]))
        }, [
          _createVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}