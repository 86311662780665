import { Module }    from 'vuex';
import { State }     from '@/views/issues/issues.types';
import { RootState } from '@/store/store.types';
import { state }     from '@/views/issues/store/issues.state';
import { mutations } from '@/views/issues/store/issues.mutations';


const store: Module<State, RootState> = {
  namespaced: true,
  state,
  mutations,
};

export default store;
