
import { computed, ComputedRef, defineComponent } from 'vue';
import { useDeviceSize }                          from '@/hooks/use-page-size';
import CMainContent                             from '@/components/main-content/main-content.component.vue';
import CControllerIssuesList                    from '@/views/controller/components/controller-issues-mobile-list.component.vue';
import CControllerIssuesTable                   from '@/views/controller/components/controller-issues-table.component.vue';
import { useGlobals }                  from '@/hooks/use-globals';
import { useIssues, UseIssuesContext } from '@/hooks/use-issues';

export default defineComponent({
  name: 'VController',
  components: { CControllerIssuesTable, CControllerIssuesList, CMainContent },
  setup() {
    const loading: ComputedRef<boolean> = computed(() => store.state.issues.loading);
    const { isMobile } = useDeviceSize();
    const { store } = useGlobals();
    const { getAllIssues } = useIssues(UseIssuesContext.CONTROLLER)

    getAllIssues()

    return {
      isMobile,
      loading,
    };
  },
});
