import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "font-bold text-16 text-gray-400 divide-x" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_issue_list_item_with_confirmation = _resolveComponent("c-issue-list-item-with-confirmation")
  const _component_c_controller_issues_details = _resolveComponent("c-controller-issues-details")

  return (_ctx.viewMode === _ctx.ControllerMobileState.LIST_MODE)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("h2", _hoisted_2, _toDisplayString(_ctx.issuesList.length ? _ctx.useTranslations('issuesList') : _ctx.useTranslations('noIssues')), 1),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.issuesList, (issue) => {
          return (_openBlock(), _createBlock(_component_c_issue_list_item_with_confirmation, {
            key: issue.uuid,
            issue: issue,
            onSetSingleIssue: _ctx.handleSelectIssue
          }, null, 8, ["issue", "onSetSingleIssue"]))
        }), 128))
      ]))
    : (_openBlock(), _createBlock(_component_c_controller_issues_details, {
        key: 1,
        onAccept: _ctx.handleAcceptIssue,
        onClosePanel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setViewMode(_ctx.ControllerMobileState.LIST_MODE)))
      }, null, 8, ["onAccept"]))
}